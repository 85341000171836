<template>
    <div class="orders">
        <div class="mb-6">
            <h1>Tutti i prestiti</h1>
        </div>

        <bxs-data-table
        ref="table"
        model-name="Order"
        resource="orders"
        id="table-orders-view"
        :headers="[
            { text: 'Origin ID', value: 'origin_id' },
            { text: 'Oggetto', value: 'product.name' },
            { text: 'Biblioteca', value: 'library' },
            { text: 'Dalla data', value: 'loan.from_date' },
            { text: 'Alla data', value: 'loan.to_date' },
            { text: 'Rinnovato fino a', value: 'loan.to_renewal_date' },
            { text: 'Nr. di giorni', value: 'loan.dates', filterable: false },
            { text: 'Stato', value: 'status' },
            { text: 'Membro', value: 'user.id.name' },
            { text: 'Email', value: 'user.id.email' },
            { text: 'Membro attivo', value: 'user.id.is.active_card' },
            { text: 'Membro servizio attivo', value: 'user.id.is.active_service' },
            { text: 'Ubicazione', value: 'location.position' },
            { text: 'Luogo di ritiro', value: 'location.address' }
        ]"
        :schema="{
            status: {
                instance: 'array',
                query_key: 'status',
                items: ['checkout', 'pending', 'approved', 'extended', 'cancelled', 'denied', 'returned']
            },
            library: {
                instance: 'array',
                query_key: 'library',
                items: $api.enums.libraries
            }
        }"
        :static-query="{
            spread: ['user.id']
        }"
        :sort-by="['loan.from_date']"
        :sort-dir="[true]">
            <template #item.user.id.name="{ item, value }">
                <router-link :to="`/products/${item.user.id}`">{{ value }}</router-link>
            </template>

            <template #item.loan.from_date="{ value }">
                <v-chip small>{{ value | toHumanDate('l') }}</v-chip>
            </template>

            <template #item.product.name="{ item, value }">
                <router-link :to="`/products/${item.product.id}`">{{ value }}</router-link>
            </template>

            <template #item.loan.to_date="{ value }">
                <v-chip
                small
                :color="!isGreaterThanNow(value) ? 'error' : null">{{ value | toHumanDate('l') }}</v-chip>
            </template>

            <template #item.loan.to_renewal_date="{ value }">{{ value | toHumanDate('l') }}</template>

            <template #item.status="{ value }">
                <v-chip
                small
                :color="value === 'pending' ? 'info' : value === 'approved' || value === 'extended' ? 'success' : value === 'cancelled' || value === 'denied' ? 'error' : null">{{ $t(value) || '--' }}</v-chip>
            </template>

            <template #item.loan.dates="{ value }">{{ value.length }}</template>
        </bxs-data-table>
    </div>
</template>

<script>
import moment from 'moment'
moment.locale('it')

export default {
    name: 'orders',
    methods: {
        isGreaterThanNow (value) {
            if (!value || !moment(value).isValid()) return value
            return moment(value).diff(moment()) >= 0
        }
    }
}
</script>