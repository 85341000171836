import Vue from 'vue'

import CommaToListField from './CommaToListField.vue'
import Formizer from './Formizer.vue'
import Loading from './Loading'
import CurrencyField from './CurrencyField.vue'
import QtyField from './QtyField.vue'
import SearchField from './SearchField.vue'
import VueCurrencyInput from 'vue-currency-input'
import Uploader from './storage/Uploader.vue'
import SelectOrderField from './SelectOrderField.vue'
import draggable from 'vuedraggable'
import DepField from './DepField'
import SeoField from './SeoField'
import WysiwygField from './WysiwygField.vue'
import LinkField from './LinkField.vue'

import BxsDateField from './BxsDateField.vue'
import BxsModal from './BxsModal.vue'
import BxsDotMenu from './BxsDotMenu.vue'
import BxsBtn from './BxsBtn.vue'
import BxsDataTable from './BxsDataTable.vue'
import BxsSelectResource from './BxsSelectResource.vue'
import DataLazy from './DataLazy.vue'
import BxsTreeView from './tree/View.vue'
import PageDetails from './PageDetails.vue'

import FormUser from './forms/FormUser.vue'
import ProductCategoriesField from './ProductCategoriesField.vue'

// libs
import { VTooltip } from 'floating-vue'
import Toast from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import 'floating-vue/dist/style.css'

Vue.use(VueCurrencyInput)
Vue.use(Toast, {
    position: 'bottom-center',
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    newestOnTop: false,
    transition: 'Vue-Toastification__fade',
    bodyClassName: ['bxs-notify'],
    maxToasts: 1
})

Vue.directive('tooltip', VTooltip)

Vue.component('bxs-date-field', BxsDateField)
Vue.component('bxs-modal', BxsModal)
Vue.component('bxs-dot-menu', BxsDotMenu)
Vue.component('bxs-btn', BxsBtn)
Vue.component('bxs-data-table', BxsDataTable)
Vue.component('bxs-select-resouce-field', BxsSelectResource)
Vue.component('bxs-data-lazy', DataLazy)
Vue.component('bxs-tree-view', BxsTreeView)
Vue.component('page-details', PageDetails)

Vue.component('form-user', FormUser)
Vue.component('product-categories-field', ProductCategoriesField)

Vue.component('loading', Loading)
Vue.component('formizer', Formizer)
Vue.component('comma-to-list-field', CommaToListField)
Vue.component('qty-field', QtyField)
Vue.component('search-field', SearchField)
Vue.component('uploader', Uploader)
Vue.component('currency-field', CurrencyField)
Vue.component('select-order-field', SelectOrderField)
Vue.component('dep-field', DepField)
Vue.component('seo-field', SeoField)
Vue.component('wysiwyg-field', WysiwygField)
Vue.component('link-field', LinkField)
Vue.component('draggable', draggable)
