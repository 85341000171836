<template>
    <div class="bxs-uploader">
        <div
        v-if="!preloaded"
        class="pa-4">
            <v-skeleton-loader type="table-heading" />
        </div>

        <div v-else>
            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                toolbar
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <div>
                <div
                v-if="label"
                class="mb-4">
                    <p class="mb-0">{{ label }}</p>
                </div>

                <!-- <div class="text-center">
                    <div v-if="!$scopedSlots.activator">
                        <div>
                            <v-btn
                            small
                            block
                            color="blue lighten-5"
                            class="blue--text text--darken-4 mb-1"
                            @click="click()">Aggiugi file</v-btn>
                        </div>

                        <v-btn
                        small
                        block
                        text
                        color="info"
                        @click="show_storage = !show_storage">Seleziona da esistenti</v-btn>
                    </div>

                    <slot
                    name="activator"
                    :on="{ click: click }" />
                </div> -->
            </div>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                files
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <div>
                <v-avatar
                v-tooltip="'Carica immagine'"
                rounded
                :size="itemMaxWidth"
                class="d-inline ma-1 pointer"
                style="display: inline;"
                @click="click()">
                    <v-img
                    lazy-src="/img/logo.svg"
                    src="/img/add-image.png"></v-img>
                </v-avatar>

                <v-avatar
                v-tooltip="'Scegli immagine da storage'"
                rounded
                :size="itemMaxWidth"
                class="d-inline ma-1 pointer"
                style="display: inline;"
                @click="show_storage = !show_storage">
                    <v-img
                    lazy-src="/img/logo.svg"
                    src="/img/add-list.png"></v-img>
                </v-avatar>

                <draggable
                v-if="files.length > 0"
                v-model="files"
                ghost-class="draggable-ghost"
                tag="ul"
                :disabled="files.length <= 1"
                class="d-inline-flex flex-wrap"
                style="display: inline;"
                @change="_updateValue">
                    <li
                    v-for="(file, i) in files"
                    :key="i"
                    :class="['ma-1', { dragging: !file.__uploading && files.length > 1 }]">
                        <v-card
                        class="pa-0"
                        style="position: relative;">
                            <v-avatar
                            v-tooltip="!i ? 'Immagine default' : null"
                            rounded
                            :size="itemMaxWidth">
                                <v-progress-circular
                                v-if="file.__uploading"
                                :width="4"
                                :size="multiple && !i ? 60 : 44"
                                color="secondary"
                                indeterminate></v-progress-circular>

                                <span v-else>
                                    <v-img
                                    v-if="file.url"
                                    :src="file.url | toThumb"
                                    aspect-ratio="1"
                                    lazy-src="/img/logo.svg" />

                                    <v-img
                                    v-else
                                    src='/img/logo.svg'
                                    aspect-ratio="1"
                                    lazy-src="/img/logo.svg" />
                                </span>
                            </v-avatar>

                            <v-btn
                            v-tooltip="'Rimuovi elemento'"
                            x-small
                            class="uploader--item--btn-remove ma-0 pa-0"
                            @click="remove(file)">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-card>
                    </li>
                </draggable>
            </div>

            <input
            ref="fileInput"
            class="hidden"
            type="file"
            :name="name"
            :id="'file-input-' + name"
            :accept="accept"
            :multiple="multiple"
            :disabled="disabled"
            @change="_handleFileInput" />
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            storage
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <!-- app right clipped -->
        <bxs-modal
        v-model="show_storage"
        title="Cerca e seleziona i file da collegare"
        width="560">
            <div class="sticky white py-3">
                <search-field
                v-model="search_keywords"
                :loading="seach_loading"
                prepend-icon="search"
                label="Cerca"
                min-height="40"
                clearable
                dense
                outlined
                hide-details
                class="mb-2" />

                <div>
                    <v-chip-group
                    v-model="search_tags"
                    multiple
                    active-class="primary">
                        <v-chip disabled>Tags</v-chip>

                        <v-chip
                        v-for="(v, i) in tags"
                        :key="i"
                        :value="v"
                        outlined
                        filter>{{ v }}</v-chip>
                    </v-chip-group>
                </div>
            </div>

            <div>
                <v-list v-if="search_items.length > 0">
                    <!-- :disabled="item_values.includes(dotObj.pick(itemValue, f))" -->
                    <v-list-item
                    v-for="f in search_items"
                    :key="f.id"
                    @click="!item_values.includes(dotObj.pick(itemValue, f)) ? pushById(f.id) : remove(f)">
                        <v-list-item-avatar>
                            <v-img
                            lazy-src="/img/logo.svg"
                            :src="f.url | toThumb(80)" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ f.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ f.alt || '' }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <!-- :disabled="item_values.includes(dotObj.pick(itemValue, f))" -->
                            <v-btn
                            icon
                            :color="item_values.includes(dotObj.pick(itemValue, f)) ? 'success' : null">
                                <v-icon>{{ item_values.includes(dotObj.pick(itemValue, f)) ? 'task_alt' : 'radio_button_unchecked' }}</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <div
                v-else
                class="text-center mute--text mt-3">
                    <p>Nessun elemento disponibile <span v-if="search_keywords">per la ricerca {{ search_keywords }}</span></p>
                </div>
            </div>
        </bxs-modal>
    </div>
</template>

<script>
// import axios from 'axios'

import dotObj from 'dot-object'
import { promiseAllProperties, getGcd } from '@/assets/libs/utils'
import {
  getMimeTypeFromBase64DataURI,
  getBase64FromFile,
  getDimensionFromFile,
  getBytesToSize,
  getExtensionFromFilename,
  getFilenameWithoutExtension,
  resetFileInput
//   getFileFromBase64Fetch
} from '@/assets/libs/file'

const getUID = (length = 10) => Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1)

export default {
    name: 'uploader-field',
    components: {
        // storage: Storage
    },
    props: {
        value: {
            type: [Array, String, Object],
            required: false,
            default: null
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        'return-object': {
            type: Boolean,
            required: false,
            default: false
        },
        block: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        },
        name: {
            type: String,
            required: false,
            default: ''
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        'check-type': {
            type: Boolean,
            required: false,
            default: true
        },
        'max-size-file': {
            type: Number,
            required: false,
            default: 5000000 // 5Mg
        },
        dimension: {
            type: Object,
            required: false,
            default: null
        },
        accept: {
            type: String,
            required: false,
            default: 'image/png, image/gif, image/jpeg, image/jpg, image/webp'
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        tag: {
            type: [String, Object],
            required: false,
            default: null
        },
        'show-files': {
            type: Boolean,
            required: false,
            default: true
        },
        immediate: {
            type: Boolean,
            required: false,
            default: true
        },
        'custom-props': {
            type: Object,
            required: false,
            default: null
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        'item-max-width': {
            type: [String, Number],
            required: false,
            default: 60
        }
    },
    computed: {
        available_types () {
            return this.accept.split(',').map((v) => v.replaceAll(' ', ''))
        },
        item_values () {
            const v = []

            this.files.forEach((i) => {
                if (dotObj.pick(this.itemValue, i)) {
                    v.push(dotObj.pick(this.itemValue, i))
                }
            })

            return v
        }
    },
    data () {
        return {
            dotObj,
            tab: 'upload',
            tabs: ['upload', 'storage'],
            files: [],
            preloaded: false,
            loading: false,
            localReturnObject: this.returnObject || !this.immediate,
            //
            tags: [],
            search_items: [],
            seach_loading: false,
            show_storage: false,
            search_keywords: null,
            search_tags: []
        }
    },
    created () {
        if (this.debug) console.log('uploader created')
        this.start()
    },
    watch: {
        returnObject (newVal) {
            this.localReturnObject = newVal
        },
        value (newVal) {
            if (this.debug) console.log('%c uploader value change: ' + newVal, 'background: #222; color: #bada55')
            this._populate()
        },
        search_keywords () {
            this.getItems()
        },
        search_tags () {
            this.getItems()
        }
    },
    methods: {
        //
        async start () {
            if (this.debug) console.log('uploader start')

            await this._populate()
            this.preloaded = true

            this.$api.distinct('File', 'tags').then((items) => {
                this.tags = items
            })

            this.$api.files.list({
                sort: { name: 1 }
            }).then(({ docs }) => {
                this.search_items = docs
            })
        },
        async getItems () {
            this.items = []

            if (!this.search_keywords && !this.search_tags.length) return

            this.seach_loading = true
            const filters = {}

            if (this.search_keywords && this.search_keywords.length >= 3) {
                filters.keywords = this.search_keywords
            }

            if (this.search_tags.length > 0) {
                filters.tags = '[]|' + this.search_tags.join(',')
            }

            const docs = await this.$api.files.list({
                pagination: false,
                filters: filters,
                sort: {
                    name: 1
                }
            })

            this.search_items = docs
            this.seach_loading = false
        },
        // publics
        async add (file) {
            if (this.debug) console.log('uploader add')

            this.loading = true

            // check mimetype
            if (this.checkType && !this.available_types.includes(file.type)) {
                return this.$emit('item-error', `File ${file.name} non supportato`)
            }

            let item = {}
            item.__selected = false
            item.__expanded = false
            item.__uploading = true
            item.__loading = false
            item.__errors = []
            item.__show_tags = false

            item.uid = getUID()
            item.id = item.uid
            item.file = file
            item.name = getFilenameWithoutExtension(file.name, true)
            item.folder = null
            item.alt = item.name
            item.url = null
            item.size = getBytesToSize(file.size)
            item.extension = getExtensionFromFilename(file.name)

            item = this.push(item)

            const { base64, dimension } = await promiseAllProperties({
                base64: getBase64FromFile(file),
                dimension: getDimensionFromFile(file)
            })

            item.base64 = base64
            item.dimension = dimension
            item.mimetype = getMimeTypeFromBase64DataURI(item.base64)

            //
            if (this.customProps && Object.keys(this.customProps).length > 0) {
                item = {
                    ...item,
                    ...this.customProps
                }
            }

            // ratio
            const r = getGcd(item.dimension.w, item.dimension.h)
            item.aspect_ratio = `${parseInt(item.dimension.w / r)}/${parseInt(item.dimension.h / r)}`

            // clear inut
            this.$refs.fileInput.value = ''
            resetFileInput(this.$refs.fileInput)

            // controls
            item.__errors = this.controls(item)

            if (item.__errors.length > 0) {
                this.$emit('item-error', item.__errors)
            }

            if (this.immediate) item = await this.upload(item)

            item.__errors = []
            item.__selected = false
            item.__expanded = false
            item.__uploading = false
            item.__loading = false
            item.__show_tags = false
            item = this.replace(item)

            this._updateValue()

            this.loading = false

            return item
        },
        async upload (item) {
            if (this.debug) console.log('uploader upload')

            try {
                // const file = await getFileFromBase64Fetch(
                //     item.base64,
                //     item.name,
                //     item.extension,
                //     item.mimetype
                // )

                // const form_data = new FormData()
                // form_data.append('customerName', 'cdn.silkandmore.it')
                // form_data.append('destPath', item.folder || 'commons')
                // form_data.append('file', file)

                // const cdn_res = await axios.post('https://cdn.bxs.io', form_data, {
                //     'Content-Type': 'multipart/form-data'
                // })

                // item.url = cdn_res.data.data[0].url

                const docs = await this.$api.files.create({
                    files: [item]
                })

                return docs[0]
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        pushById (id) {
            if (this.debug) console.log('pushById', id)

            if (!id) return

            this.$api.files.doc(id).then((d) => {
                if (d) {
                    this.push(d)
                    this._updateValue()
                }
            })
        },
        push (item) {
            if (!item) return

            if (this.multiple) {
                if (!this.item_values.includes(dotObj.pick(this.itemValue, item))) {
                    if (this.debug) console.log('uploader push', item)
                    this.files.push(JSON.parse(JSON.stringify(item)))
                }
            } else {
                if (this.debug) console.log('uploader push', item)
                this.files = [JSON.parse(JSON.stringify(item))]
            }

            return item
        },
        replace (item) {
            if (this.debug) console.log('uploader replace')

            const index = this.files.findIndex(i => i.uid === item.uid)
            this.files.splice(index, 1)
            this.files.push(JSON.parse(JSON.stringify(item)))
            return item
        },
        controls (item, callback) {
            if (this.debug) console.log('uploader controls')

            const err = []

            // size
            // if (item.size.bytes > this.max.size) {
            //     err.push(`File ${item.name} troppo pesante, massimo 20 Megabyte`)
            // }

            // // check min dimension w
            // if (item.dimension.w < this.min.w || item.dimension.h < this.min.h) {
            //     err.push(`File ${item.name} inferiore alle dimensioni minime consentite`)
            // }

            if (callback && typeof callback === 'function') return callback(err)

            return err
        },
        click () {
            return this.$refs.fileInput.click()
        },
        remove (item) {
            if (this.debug) console.log('uploader remove', item)

            const index = this.files.findIndex(i => i.uid === item.uid)
            this.files.splice(index, 1)
            this.$emit('remove', { item, index })

            this._updateValue()
        },
        // privates
        _asScopeSlot (key) {
            return key in this.$scopedSlots
        },
        _handleFileInput (evt) {
            const files = [...evt.target.files]
            files.forEach(this.add)
        },
        async _populate () {
            if (this.value && this.value.length > 0) {
                let vals = []
                const isArrOfObjs = this.multiple && Array.isArray(this.value) && this.value.every(i => typeof i === 'object')

                if (isArrOfObjs) {
                    this.value.forEach((item) => {
                        vals.push(dotObj.pick(this.itemValue, item))
                    })
                } else {
                    vals = this.multiple ? this.value : [this.value]
                }

                if (this.files.length > 0) {
                    vals = vals.filter(v => this.files.some(_v => dotObj.pick(this.itemValue, _v) !== v))
                }

                if (vals.length > 0) {
                    const files = await this.$api.files.list({
                        pagination: false,
                        filters: {
                            id: '[]|' + vals.join(',')
                        }
                    })

                    if (this.debug) console.log('uploader _populate', vals)

                    vals.forEach((val) => {
                        const item = files.find(i => dotObj.pick(this.itemValue, i) === val)

                        if (item) {
                            item.__errors = []
                            item.__selected = false
                            item.__expanded = false
                            item.__uploading = false
                            item.__loading = false
                            item.__show_tags = false
                            this.push(item)
                        }
                    })

                    this._updateValue()
                }
            } else {
                this.files = []
            }
        },
        _updateValue () {
            if (this.debug) console.log('%c _updateValue', 'background: #222; color: #bada55', this.item_values)

            this.$nextTick(() => {
                if (!this.localReturnObject) {
                    return this.$emit('input', this.multiple ? this.item_values : this.item_values[0])
                }

                return this.$emit('input', this.multiple ? this.files : this.files[0])
            })
        }
    }
}
</script>

<style lang="scss">
.uploader--item--btn-remove {
    position: absolute !important;
    top: 5px !important;
    right: 10px !important;
    width: 10% !important;
    height: 20% !important;
    max-width: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    min-height: unset !important;
    z-index: 1 !important;
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;
    color: red !important;

    i {
        font-size: 1.25rem !important;
    }
}
</style>

<style lang="scss" scoped>
ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.bxs-uploader {
    position: relative;
}

.hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    word-wrap: normal;
}
</style>
