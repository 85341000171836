<template>
    <div class="product-categories-field">
        <v-row
        v-for="(item, i) in items"
        :key="i"
        no-gutters
        align="center"
        class="mb-4">
            <v-col cols="4">
                <v-text-field
                v-model="item.name"
                label="Nome"
                placeholder="* Nome"
                outlined
                hide-details
                dense
                class="pr-1" />
            </v-col>

            <v-col cols="4">
                <v-text-field
                v-model="item.slug"
                label="Slug"
                placeholder="Inserisci lo slug"
                outlined
                hide-details
                dense
                class="pl-1" />
            </v-col>

            <v-col
            cols="4"
            class="text-right">
                <v-btn
                v-if="i === items.length - 1"
                icon
                outlined
                x-small
                color="success"
                class="mr-2"
                @click="add">
                    <v-icon size="12">add</v-icon>
                </v-btn>

                <v-btn
                v-if="items.length > 1"
                icon
                outlined
                x-small
                color="error"
                @click="remove(i)">
                    <v-icon size="12">remove</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'product_categories_field',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            items: this.value
        }
    },
    created () {
        if (!this.items.length) {
            this.items = [{ name: '', slug: '' }]
        }
    },
    watch: {
        items () {
            this.$emit('input', this.items)
        }
    },
    methods: {
        add () {
            this.items.push({ name: '', slug: '' })
            this.$emit('add')
        },
        remove (i) {
            this.items.splice(i, 1)
            this.$$emit('remove')
        }
    }
}
</script>