import Resource from './BaseResource'

class Configurator extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)
    }
}

class Product extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)

        this.configurators = new Configurator(config, tools, 'v1', 'configurators')
    }
}

export default Product
