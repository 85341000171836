var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products"},[_vm._m(0),_c('v-card',{staticClass:"pa-0"},[_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Product","resource":"products","to-create-item":"/products/new","id":"table-products-view","sort-by":['name'],"sort-dir":[false],"static-query":{
            spread: ['location.id', 'owner']
        },"schema":{
            'loan.status': {
                instance: 'array',
                query_key: 'loan.status',
                items: [
                    { text: 'Disponibile', value: 'available' },
                    { text: 'In prestito', value: 'busy' },
                    { text: 'In manutenzione', value: 'maintenance' }
                ]
            },
            library: {
                instance: 'array',
                query_key: 'library',
                items: _vm.$api.enums.libraries
            },
            categories: {
                instance: 'array',
                query_key: 'categories.name',
                items: _vm.$api.enums.categories
            }
        },"headers":[
            { text: '', value: 'imgs' },
            { text: 'Nome', value: 'name' },
            { text: 'Biblioteca', value: 'library' },
            { text: 'Proprietario', value: 'owner.email' },
            { text: 'Corner', value: 'location.id' },
            { text: 'Ubicazione', value: 'location.position' },
            { text: 'Categoria/e', value: 'categories' },
            { text: 'Stato', value: 'loan.status' },
            { text: 'Data di ritorno', value: 'loan.to_date' },
            { text: 'Max mesi prestito', value: 'loan.max_months' },
            { text: 'Attesa preparazione', value: 'loan.preparation_days' },
            { text: 'Lista attesa', value: 'waiting_list' }
        ]},scopedSlots:_vm._u([{key:"item.imgs",fn:function({ value }){return [_c('div',{staticClass:"py-2"},[(value && value.length > 0)?_c('v-img',{attrs:{"src":_vm._f("toStaticFile")(value[0]),"max-width":"50"}}):_c('v-img',{attrs:{"src":"/img/no-photo.png","max-width":"50"}})],1)]}},{key:"item.library",fn:function({ item, value }){return [(_vm.$api.auth.is_super)?_c('v-text-field',{attrs:{"solo":"","dense":"","filled":"","flat":"","hide-details":"","single-line":""},on:{"change":function($event){return _vm.$api.products.modify(item.id, { library: $event })}},model:{value:(item.library),callback:function ($$v) {_vm.$set(item, "library", $$v)},expression:"item.library"}}):_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.owner.email",fn:function({ value }){return [_vm._v(_vm._s(value || '--'))]}},{key:"item.loan.preparation_days",fn:function({ value }){return [_vm._v(_vm._s(value)+" gg.")]}},{key:"item.categories",fn:function({ value }){return [_vm._v(_vm._s(value.length > 0 ? value.map(i => i.name).join(',') : '--'))]}},{key:"item.loan.status",fn:function({ item, value }){return [(_vm.$api.auth.is_super)?_c('v-select',{attrs:{"items":[
                    { text: 'Disponibile', value: 'available' },
                    { text: 'In prestito', value: 'busy' },
                    { text: 'In manutenzione', value: 'maintenance' }
                ],"hide-details":"","single-line":"","flat":"","filled":"","dense":"","solo":"","chips":"","small-chips":""},on:{"change":function($event){return _vm.$api.products.modify(item.id, { 'loan.status': $event })}},model:{value:(item.loan.status),callback:function ($$v) {_vm.$set(item.loan, "status", $$v)},expression:"item.loan.status"}}):_c('v-chip',{attrs:{"small":"","color":value === 'available' ? 'success' : 'warning'}},[_vm._v(_vm._s(_vm.$t(value)))])]}},{key:"item.loan.to_date",fn:function({ value }){return [(value)?_c('span',[_vm._v(_vm._s(_vm._f("toHumanDate")(value,'ll')))]):_c('span',[_vm._v("--")])]}},{key:"item.location.id",fn:function({ value }){return [_vm._v(_vm._s(value ? value.name : '--'))]}},{key:"item.location.position",fn:function({ value }){return [_vm._v(_vm._s(value || '--'))]}},{key:"item-actions",fn:function({ id }){return [_c('v-list-item',{attrs:{"to":`/products/${id}`}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modifica")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Tutti gli oggetti")])])
}]

export { render, staticRenderFns }