import dot from 'dot-object'

const ts = {
    categories: 'Categoria/e',
    'name.first': 'Nome',
    'name.last': 'Cognome',
    name: 'Nome',
    'name.it': 'Nome it',
    'name.en': 'Nome en',
    'scopes.reads': 'Permessi lettura',
    'scopes.writes': 'Permessi scrittura',
    created_at: 'Data creazione',
    false: 'No',
    id: 'Identificativo interno',
    img: 'Immagine',
    imgs: 'Immagini',
    role: 'Ruolo',
    roles: 'Ruoli',
    type: 'Tipologia',
    title: 'Titolo',
    true: 'Si',
    types: 'Tipo',
    updated_at: 'Data aggiornamento',
    products: 'Prodotti',
    available: 'Disponibile',
    busy: 'In prestito',
    returned: 'Restituito',
    pending: 'In attesa',
    approved: 'Approvato',
    extended: 'Esteso',
    cancelled: 'Annullato',
    denied: 'Negato'
}

const t = (k) => {
    if (typeof k === 'object' || typeof k === 'number') return k

    const vk = String(k)
    return ts[vk] || dot.pick(vk, ts) || k
}

export default t