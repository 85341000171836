<template>
    <div class="products">
        <div class="mb-8">
            <h1>Tutti gli oggetti</h1>
        </div>

        <!-- to-create-item="/products/new" -->
        <v-card class="pa-0">
            <bxs-data-table
            ref="table"
            model-name="Product"
            resource="products"
            to-create-item="/products/new"
            id="table-products-view"
            :sort-by="['name']"
            :sort-dir="[false]"
            :static-query="{
                spread: ['location.id', 'owner']
            }"
            :schema="{
                'loan.status': {
                    instance: 'array',
                    query_key: 'loan.status',
                    items: [
                        { text: 'Disponibile', value: 'available' },
                        { text: 'In prestito', value: 'busy' },
                        { text: 'In manutenzione', value: 'maintenance' }
                    ]
                },
                library: {
                    instance: 'array',
                    query_key: 'library',
                    items: $api.enums.libraries
                },
                categories: {
                    instance: 'array',
                    query_key: 'categories.name',
                    items: $api.enums.categories
                }
            }"
            :headers="[
                { text: '', value: 'imgs' },
                { text: 'Nome', value: 'name' },
                { text: 'Biblioteca', value: 'library' },
                { text: 'Proprietario', value: 'owner.email' },
                { text: 'Corner', value: 'location.id' },
                { text: 'Ubicazione', value: 'location.position' },
                { text: 'Categoria/e', value: 'categories' },
                { text: 'Stato', value: 'loan.status' },
                { text: 'Data di ritorno', value: 'loan.to_date' },
                { text: 'Max mesi prestito', value: 'loan.max_months' },
                { text: 'Attesa preparazione', value: 'loan.preparation_days' },
                { text: 'Lista attesa', value: 'waiting_list' }
            ]">
                <template #item.imgs="{ value }">
                    <div class="py-2">
                        <v-img
                        v-if="value && value.length > 0"
                        :src="value[0] | toStaticFile"
                        max-width="50" />

                        <v-img
                        v-else
                        src="/img/no-photo.png"
                        max-width="50" />
                    </div>
                </template>

                <template #item.library="{ item, value }">
                    <v-text-field
                    v-if="$api.auth.is_super"
                    v-model="item.library"
                    solo
                    dense
                    filled
                    flat
                    hide-details
                    single-line
                    @change="$api.products.modify(item.id, { library: $event })" />

                    <span v-else>{{ value }}</span>
                </template>

                <template #item.owner.email="{ value }">{{ value || '--' }}</template>

                <template #item.loan.preparation_days="{ value }">{{ value }} gg.</template>

                <template #item.categories="{ value }">{{ value.length > 0 ? value.map(i => i.name).join(',') : '--' }}</template>

                <template #item.loan.preparation_days="{ value }">{{ value }} gg.</template>

                <template #item.loan.status="{ item, value }">
                    <v-select
                    v-if="$api.auth.is_super"
                    v-model="item.loan.status"
                    :items="[
                        { text: 'Disponibile', value: 'available' },
                        { text: 'In prestito', value: 'busy' },
                        { text: 'In manutenzione', value: 'maintenance' }
                    ]"
                    hide-details
                    single-line
                    flat
                    filled
                    dense
                    solo
                    chips
                    small-chips
                    @change="$api.products.modify(item.id, { 'loan.status': $event })" />

                    <v-chip
                    v-else
                    small
                    :color="value === 'available' ? 'success' : 'warning'">{{ $t(value) }}</v-chip>
                </template>

                <template #item.loan.to_date="{ value }">
                    <span v-if="value">{{ value | toHumanDate('ll') }}</span>
                    <span v-else>--</span>
                </template>

                <template #item.location.id="{ value }">{{ value ? value.name : '--' }}</template>

                <template #item.location.position="{ value }">{{ value || '--' }}</template>

                <template #item-actions="{ id }">
                    <v-list-item :to="`/products/${id}`">
                        <v-list-item-icon>
                            <v-icon>edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Modifica</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </bxs-data-table>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'products',
    data () {
        return {
            libraries: []
        }
    },
    created () {
        // this.$api.libraries.list({
        //     pagination: false,
        //     sort: { name: 1 }
        // }).then((docs) => {
        //     docs.forEach((l) => {
        //         this.libraries.push({
        //             text: l.name,
        //             value: l.id
        //         })
        //     })
        // })
    }
}
</script>