<template>
    <v-dialog
    v-model="on"
    persistent
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :overlay-opacity="overlayOpacity"
    :width="width"
    :max-width="maxWidth">
        <v-card
        elevation="0"
        flat
        outlined
        class="pa-0">
            <div class="pa-4">
                <div class="d-flex align-center">
                    <h2 class="mb-0">
                        <span>{{ title }}</span>
                        <slot name="title" />
                    </h2>

                    <v-spacer></v-spacer>

                    <v-btn
                    icon
                    color="error"
                    @click="close">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>

                <slot name="header" />
            </div>
            <v-divider class="ma-0 pa-0"></v-divider>

            <div
            class="ma-0 pa-0 px-8"
            :style="{ height: bodyHeight }"
            style="backface-visibility: hidden; flex: 1 1 auto; overflow-y: auto;">
                <slot />
            </div>

            <v-divider
            v-if="!hideActions"
            class="ma-0 pa-0"></v-divider>
            <v-card-actions
            v-if="!hideActions"
            class="ma-0 pa-0 pa-4">
                <v-spacer></v-spacer>

                <bxs-btn
                v-if="!hideClose"
                text
                @click="on = false">Chiudi</bxs-btn>

                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'bxs-modal',
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        width: {
            type: [String, Number],
            required: false,
            default: null
        },
        'max-width': {
            type: [String, Number],
            required: false,
            default: 512
        },
        'body-height': {
            type: String,
            required: false,
            default: '50vh'
        },
        fullscreen: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-actions': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-close': {
            type: Boolean,
            required: false,
            default: false
        },
        scrollable: {
            type: Boolean,
            required: false,
            default: true
        },
        'overlay-opacity': {
            type: [String, Number],
            required: false,
            default: 0.8
        }
    },
    data () {
        return {
            on: this.value
        }
    },
    watch: {
        value (newVal) {
            this.on = newVal
        },
        on (newVal) {
            this.$emit('input', newVal)
        }
    },
    methods: {
        close () {
            this.on = false
            this.$emit('close')
        }
    }
}
</script>