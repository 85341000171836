<template>
    <div>
        <div class="mb-8">
            <h1>Rinnovi tessere</h1>
        </div>

        <v-card class="pa-0">
            <bxs-data-table
            ref="table"
            model-name="UserRenew"
            resource="users_renews"
            id="table-users_renews-view"
            :sort-by="['created_at']"
            :sort-dir="[true]"
            :hide-default-delete-item="$api.auth.me.id === 'leila'"
            :static-query="{
                spread: ['user']
            }"
            :headers="[
                { text: 'Data ordine', value: 'created_at' },
                { text: 'Nome', value: 'user.name' },
                { text: 'Numero tessera', value: 'user.card_number' },
                { text: 'Email', value: 'user.email' },
                { text: 'Tipologia rinnovo', value: 'card.name' },
                { text: 'Stato', value: 'payment.status' },
                { text: 'Data rinnovo', value: 'sub_date.label' },
                { text: 'Prezzo tessera', value: 'card.price' }
            ]">
                <template #item.payment.status="{ value }">
                    <v-chip
                    small
                    :outlined="value === 'completed'"
                    :color="value === 'completed' ? 'success' : null">{{ value }}</v-chip>
                </template>

                <!-- <template #item.card.name="{ value }">
                    <v-chip small class="text-uppercase">{{ value }}</v-chip>
                </template> -->

                <!-- <template #item.card.price="{ value }">{{ value | toHumanPrice }}</template> -->
            </bxs-data-table>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'members_renews'
}
</script>