<template>
    <div>
        <v-card
        max-width="320"
        class="google-item mb-12 pa-3 truncated">
            <a
            :href="`${$api.enums.origins.app}/${slugify(local_fields.slug.model)}`"
            target="_blank"
            class="g-link ma-0"
            style="color: grey; text-decoration: none">{{ local_fields.permalink.model }}</a>

            <!--  -->
            <p class="g-title ma-0">
                <span v-if="local_fields.meta.title.model">{{ local_fields.meta.title.model }}</span>
                <span v-else>Titolo pagina</span>
            </p>

            <!--  -->
            <p class="g-desc ma-0">
                <span v-if="local_fields.meta.description.model">{{ local_fields.meta.description.model }}</span>
                <span v-else>Descrizione pagina.</span>
            </p>
        </v-card>

        <div>
            <v-text-field
            v-model="local_fields.slug.model"
            dense
            counter
            outlined
            label="Slug pagina" />

            <!-- canonical auto -->
            <!-- <v-text-field
            v-model="local_fields.meta.canonical.model"
            dense
            counter
            label="Canonical" /> -->

            <v-text-field
            v-model="local_fields.meta.title.model"
            dense
            counter
            outlined
            label="Titolo"
            maxlength="70" />

            <v-textarea
            v-model="local_fields.meta.description.model"
            label="Descrizione"
            counter
            auto-grow
            dense
            outlined
            maxlength="165" />
        </div>

        <!-- <v-divider class="my-4"></v-divider> -->

        <div>
            <v-text-field
            v-model="local_fields.meta.og.title.model"
            dense
            counter
            outlined
            label="og:title" />

            <v-text-field
            v-model="local_fields.meta.og.description.model"
            dense
            counter
            outlined
            label="og:description" />

            <v-text-field
            v-model="local_fields.meta.og.type.model"
            dense
            outlined
            counter
            label="og:type" />

            <v-text-field
            v-model="local_fields.meta.og.url.model"
            dense
            counter
            outlined
            label="og:url" />

            <v-card>
                <div class="mb-4">
                    <h4 class="mb-0">og:img</h4>
                </div>

                <uploader
                ref="uploader"
                v-model="local_fields.meta.og.img.ref.model"
                class="uploader-card mb-6" />

                <v-text-field
                v-model="local_fields.meta.og.img.width.model"
                dense
                counter
                outlined
                label="og:img:width" />

                <v-text-field
                v-model="local_fields.meta.og.img.height.model"
                dense
                counter
                hide-details
                outlined
                label="og:img:height" />
            </v-card>
        </div>
    </div>
</template>

<script>
import { slugify } from '@/assets/libs/utils'

export default {
    name: 'seo-field',
    props: {
        fields: {
            type: Object,
            required: true
        },
        paths: {
            type: String,
            required: false,
            default: ''
        }
    },
    data () {
        return {
            slugify,
            local_fields: this.fields
        }
    }
}
</script>