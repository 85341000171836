<template>
    <div class="index">
        <v-toolbar
        flat
        class="mb-8">
            <h2>Overview</h2>

            <v-spacer></v-spacer>

            <v-btn
            text
            @click="getStatus">
                <v-icon left>refresh</v-icon>
                <span>Ricarica</span>
            </v-btn>
        </v-toolbar>

        <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
        -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <v-row>
            <v-col cols="3">
                <v-card>
                    <div class="mb-8">
                        <p class="mute--text">Zoho Developer API's</p>
                    </div>

                    <h1>
                        <span v-if="status && status.zoho && status.zoho.api">{{ status.zoho.api.currentcount }}/{{ status.zoho.api.maximumcount }}</span>
                        <span v-else>--</span>
                        <v-chip x-small label class="ml-2">Day</v-chip>
                    </h1>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card>
                    <div class="mb-8">
                        <p class="mute--text">Zoho Email Notifications</p>
                    </div>

                    <h1>
                        <span v-if="status && status.zoho && status.zoho.email_notifications">{{ status.zoho.email_notifications.currentcount }}/{{ status.zoho.email_notifications.maximumcount }}</span>
                        <span v-else>--</span>
                        <v-chip x-small label class="ml-2">Day</v-chip>
                    </h1>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card>
                    <div class="mb-8">
                        <p class="mute--text">Zoho Sender Emails</p>
                    </div>

                    <h1>
                        <span v-if="status && status.zoho && status.zoho.sender_emails">{{ status.zoho.sender_emails.currentcount }}/{{ status.zoho.sender_emails.maximumcount }}</span>
                        <span v-else>--</span>
                        <v-chip x-small label class="ml-2">Day</v-chip>
                    </h1>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card>
                    <div class="mb-8">
                        <p class="mute--text">Zoho Schedules</p>
                    </div>

                    <h1>
                        <span v-if="status && status.zoho && status.zoho.schedules">{{ status.zoho.schedules.currentcount }}/{{ status.zoho.schedules.maximumcount }}</span>
                        <span v-else>--</span>
                        <v-chip x-small label class="ml-2">Month</v-chip>
                    </h1>
                </v-card>
            </v-col>
        </v-row>

        <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
        -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <v-row>
            <v-col cols="3">
                <v-card
                height="160"
                class="mb-2">
                    <div class="mb-4">
                        <h3>Membri attivi</h3>
                    </div>

                    <v-sheet
                    color="blue lighten-5"
                    class="py-6 text-center">
                        <h1 class="blue--text" style="font-size: 3rem;">
                            <span v-if="status">{{ status.numero_membri_attivi }}/{{ status.numero_membri }}</span>
                            <span v-else>--</span>
                        </h1>
                    </v-sheet>
                </v-card>

                <v-card height="160">
                    <div class="mb-4">
                        <h3>Membri utilizzatori</h3>
                    </div>

                    <v-sheet
                    color="blue lighten-5"
                    class="py-6 text-center">
                        <h1 class="blue--text" style="font-size: 3rem;">
                            <span v-if="status">{{ status.numero_membri_utilizzatori }}/{{ status.numero_membri }}</span>
                            <span v-else>--</span>
                        </h1>
                    </v-sheet>
                </v-card>
            </v-col>

            <v-col cols="9">
                <v-card class="mb-2">
                    <div class="mb-4">
                        <h3>Oggetti in prestito</h3>
                    </div>

                    <v-data-table
                    v-if="status"
                    :headers="[
                        { text: 'Nome', value: 'name'  },
                        { text: 'Biblioteca', value: 'library', width: '1%' },
                        { text: 'Data di ritorno', value: 'loan.to_date', width: '1%' }
                    ]"
                    :items="status.oggetti_in_prestito"
                    :items-per-page="10"
                    :footer-props="{
                        'items-per-page-options': [5, 10],
                        showFirstLastPage: true
                    }"
                    dense
                    fixed-header
                    height="320">
                        <template #item.loan.to_date="{ value }">
                            <span v-if="value">{{ value | toHumanDate('ll') }}</span>
                            <span v-else>--</span>
                        </template>
                    </v-data-table>
                </v-card>

                <v-card>
                    <div class="mb-4">
                        <h3>Oggetti piaciuti</h3>
                    </div>

                    <v-data-table
                    v-if="status"
                    :headers="[
                        { text: 'Nome', value: 'name'  },
                        { text: 'Prestiti totali', value: 'count' }
                    ]"
                    :items="status.oggetti_piaciuti"
                    :items-per-page="10"
                    :footer-props="{
                        'items-per-page-options': [5, 10],
                        showFirstLastPage: true
                    }"
                    dense
                    fixed-header
                    height="320" />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'home',
    data () {
        return {
            status: null
        }
    },
    created () {
        this.getStatus()
    },
    methods: {
        getStatus () {
            this.status = null
            this.$api.getStatus().then((doc) => {
                this.status = doc
            })
        }
    }
}
</script>