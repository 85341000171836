export function auth(roles, { ctx, to, from, router, next }) {
    const api = ctx.$api

    if (typeof roles === 'string') {
        roles = roles.split(',').map(i => i.toUpperCase())
    }

    // no auth
    if (!api.auth.me) {
        if (to.name === 'signin' || to.name === 'signup') return next()
        return next('/signin')
    }

    // si auth
    if (to.name === 'signin' || to.name === 'signup') {
        return next('/')
    }

    if (api.auth.me.id === 'leila') {
        // if (to.name !== 'members_renews') return next('/histories')
        if (to.name !== 'histories') return next('/histories')
    }

    return next()
}

export function saveParams({ to, from, router, next }) {
    if (!to.params) return router.push('/').catch(() => { })
    return next()
}

export function saveQuery({ to, from, router, next }) {
    if (!to.query) return router.push('/').catch(() => { })
    return next()
}

//

export function nextFactory(context, middlewares, index) {
    const subSequentMiddleware = middlewares[index]
    if (!subSequentMiddleware) return context.next

    return (...parameters) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middlewares, index + 1)
        subSequentMiddleware({ ...context, next: nextMiddleware })
    }
}