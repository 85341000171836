<template>
    <div class="signin signin-recovery">
        <v-row align="center" justify="center">
            <v-col
            cols="12"
            md="6"
            order="1"
            order-md="2">
                <v-card
                flat
                max-width="340"
                class="mx-auto">
                    <div class="mb-12">
                        <v-img
                        src="/img/logo.png"
                        max-width="80"
                        class="mb-4" />

                        <h2>Invia la mail per <br> reimpostare la password</h2>
                    </div>

                    <v-card-text class="ma-0 pa-0">
                        <v-text-field
                        v-model="email"
                        :disabled="loading"
                        label="Email"
                        dense
                        hide-details
                        outlined
                        required
                        prepend-inner-icon="email"
                        class="mb-4" />

                        <v-alert
                        v-if="error"
                        border="left"
                        text
                        dense
                        type="error"
                        class="ma-0 my-4">
                            <small>{{ error }}</small>
                        </v-alert>
                    </v-card-text>

                    <v-card-actions class="ma-0 pa-0">
                        <v-btn
                        :disabled="!email"
                        block
                        color="primary"
                        :loading="loading"
                        @click="recovery">Invia</v-btn>
                    </v-card-actions>

                    <div class="mt-6">
                        <v-btn
                        text
                        x-small
                        exact
                        to="/signin">Accedi</v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'signin_recovery',
    data () {
        return {
            email: '',
            error: null,
            loading: false,
            showPass: false
        }
    },
    methods: {
        async recovery () {
            this.loading = true
            this.error = null

            try {
                await this.$api.auth.passwordRecovery(this.email)
                this.$router.push('/signin/reset')
            } catch (err) {
                this.error = err.message || err.name || err
                throw new Error(this.error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.container-signin {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
}
</style>

<style lang="scss" scoped>
.signin {
    padding: 0 !important;
    overflow: hidden;

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        height: 100vh;
    }

    h1 {
        font-size: 40px;
    }

    .row {
        width: 100%;
        position: relative !important;
        margin: 0 !important;
        padding: 0 !important;
        min-height: 100% !important;
        height: 100% !important;
    }

    &--col-l {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 40px 0 !important;
        margin: 0 !important;
    }

    &--col-r {
        padding: 40px 0 !important;
        margin: 0 !important;
    }
}

.signin--figure {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-clip-path: polygon(0 0,28% 0,100% 100%,0 100%);
    clip-path: polygon(0 0,28% 0,100% 100%,0 100%);
    background-color: var(--v-primary-base);
    z-index: 0;
    background-repeat: repeat;
}
</style>