<template>
    <div class="link-field">
        <v-dialog
        v-model="on"
        max-width="400px"
        permanent
        scrollable>
            <template #activator="{ on, attrs }">
                <slot
                v-if="$scopedSlots.activator"
                name="activator"
                :on="on"
                :attrs="attrs" />

                <v-text-field
                v-else
                v-bind="attrs"
                v-on="on"
                v-model="local_value"
                :label="label"
                :required="required"
                :outlined="outlined"
                :dense="dense"
                readonly
                placeholder="Scegli il link"
                :rules="rules" />
            </template>

            <v-card class="pa-0">
                <v-card-title class="d-flex my-0 py-2">
                    <h4>Seleziona il link da collegare</h4>

                    <v-spacer></v-spacer>

                    <v-btn
                    icon
                    color="error"
                    @click="on = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>

                <!--  -->
                <v-card-text
                style="max-height: 50vh;"
                class="pa-4 ma-0">
                    <div>
                        <search-field
                        v-model="keywords"
                        dense
                        outlined />

                        <v-chip-group
                        v-model="view"
                        mandatory
                        active-class="primary">
                            <v-chip value="products">Prodotti</v-chip>
                            <v-chip value="categories">Categorie</v-chip>
                            <v-chip value="pages">Pagine</v-chip>
                        </v-chip-group>
                    </div>

                    <!--  -->
                    <div>
                        <div v-if="loading">
                            <loading />
                        </div>

                        <div
                        v-else
                        class="py-2">
                            <div
                            v-show="view === 'products'"
                            class="mb-4">
                                <div
                                v-if="!items.products.length"
                                class="text-center">
                                    <p>Nessun elemento disponibile</p>
                                </div>

                                <v-virtual-scroll
                                v-else
                                :items="items.products"
                                height="300"
                                item-height="60">
                                    <template v-slot:default="{ item }">
                                        <v-list-item
                                        :key="item.id"
                                        :value="local_value === dot.pick(itemValue, item)"
                                        active-class="success"
                                        dense
                                        @click="select(item)">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.permalink }}</v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-btn
                                                icon
                                                :color="local_value === dot.pick(itemValue, item) ? 'success' : null">
                                                    <v-icon>{{ local_value === dot.pick(itemValue, item) ? 'task_alt' : 'radio_button_unchecked' }}</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>

                                        <v-divider></v-divider>
                                    </template>
                                </v-virtual-scroll>
                            </div>

                            <div
                            v-show="view === 'categories'"
                            class="mb-3">
                                <div
                                v-if="!items.categories.length"
                                class="text-center">
                                    <p>Nessun elemento disponibile</p>
                                </div>

                                <v-virtual-scroll
                                v-else
                                :items="items.categories"
                                height="300"
                                item-height="60">
                                    <template v-slot:default="{ item }">
                                        <v-list-item
                                        :key="item.id"
                                        :value="local_value === dot.pick(itemValue, item)"
                                        active-class="success"
                                        dense
                                        @click="select(item)">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.path }}</v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-btn
                                                icon
                                                :color="local_value === dot.pick(itemValue, item) ? 'success' : null">
                                                    <v-icon>{{ local_value === dot.pick(itemValue, item) ? 'task_alt' : 'radio_button_unchecked' }}</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>

                                        <v-divider></v-divider>
                                    </template>
                                </v-virtual-scroll>
                            </div>

                            <div
                            v-show="view === 'pages'"
                            class="mb-3">
                                <div
                                v-if="!items.pages.length"
                                class="text-center">
                                    <p>Nessun elemento disponibile</p>
                                </div>

                                <v-virtual-scroll
                                v-else
                                :items="items.pages"
                                height="300"
                                item-height="60">
                                    <template v-slot:default="{ item }">
                                        <v-list-item
                                        :key="item.id"
                                        :value="local_value === dot.pick(itemValue, item)"
                                        active-class="success"
                                        dense
                                        @click="select(item)">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.permalink }}</v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-btn
                                                icon
                                                :color="local_value === dot.pick(itemValue, item) ? 'success' : null">
                                                    <v-icon>{{ local_value === dot.pick(itemValue, item) ? 'task_alt' : 'radio_button_unchecked' }}</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>

                                        <v-divider></v-divider>
                                    </template>
                                </v-virtual-scroll>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-divider class="ma-0 pa-0"></v-divider>
                <v-card-actions class="ma-0 pa-0 pa-4">
                    <v-spacer></v-spacer>
                    <bxs-btn
                    text
                    @click="on = false">Annulla e chiudi</bxs-btn>

                    <slot name="actions" />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import dot from 'dot-object'

export default {
    name: 'link-field',
    props: {
        value: {
            type: String,
            required: false,
            default: null
        },
        'item-value': {
            type: String,
            required: false,
            default: 'permalink'
        },
        label: {
            type: String,
            required: false,
            default: 'Link'
        },
        required: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        'close-on-click': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            dot,
            local_value: this.value,
            on: false,
            loading: true,
            keywords: null,
            view: 'products',
            items: {
                categories: [],
                products: [],
                pages: []
            }
        }
    },
    created () {
        this.getLinks()
    },
    // mounted () {
    //     this.$nextTick(() => {
    //         this.$refs.product_scroller.addEventListener('scroll', () => {
    //             if ((this.$refs.product_scroller.innerHeight + this.$refs.product_scroller.scrollY) >= (this.$refs.product_scroller.scrollHeight - (this.$refs.product_scroller.innerHeight / 2))) {
    //                 if (this.itmes.products.pagination.hasNextPage) {
    //                     this.getProducts()
    //                 }
    //             }
    //         })
    //     })
    // },
    watch: {
        value () {
        },
        keywords () {
            this.getLinks()
        }
    },
    methods: {
        // getProducts () {
        //     this.items.products = await this.$api.products.list()
        // },
        async getLinks () {
            this.loading = true
            this.items = await this.$api.get('/v1/links', {
                keywords: this.keywords
            })
            this.loading = false
        },
        select (item) {
            this.local_value = dot.pick(this.itemValue, JSON.parse(JSON.stringify(item)))
            this.$emit('input', this.local_value)
            this.$emit('change', {
                id: item.id,
                permalink: this.local_value,
                slug: item.slug || null,
                label: item.name || item.label || item.title,
                type: item.type
            })
            if (this.closeOnClick) this.on = false
        }
    }
}
</script>