var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Order","resource":"orders","id":"table-orders-view","headers":[
        { text: 'Origin ID', value: 'origin_id' },
        { text: 'Oggetto', value: 'product.name' },
        { text: 'Biblioteca', value: 'library' },
        { text: 'Dalla data', value: 'loan.from_date' },
        { text: 'Alla data', value: 'loan.to_date' },
        { text: 'Rinnovato fino a', value: 'loan.to_renewal_date' },
        { text: 'Nr. di giorni', value: 'loan.dates', filterable: false },
        { text: 'Stato', value: 'status' },
        { text: 'Membro', value: 'user.id.name' },
        { text: 'Email', value: 'user.id.email' },
        { text: 'Membro attivo', value: 'user.id.is.active_card' },
        { text: 'Membro servizio attivo', value: 'user.id.is.active_service' },
        { text: 'Ubicazione', value: 'location.position' },
        { text: 'Luogo di ritiro', value: 'location.address' }
    ],"schema":{
        status: {
            instance: 'array',
            query_key: 'status',
            items: ['checkout', 'pending', 'approved', 'extended', 'cancelled', 'denied', 'returned']
        },
        library: {
            instance: 'array',
            query_key: 'library',
            items: _vm.$api.enums.libraries
        }
    },"static-query":{
        spread: ['user.id']
    },"sort-by":['loan.from_date'],"sort-dir":[true]},scopedSlots:_vm._u([{key:"item.user.id.name",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":`/products/${item.user.id}`}},[_vm._v(_vm._s(value))])]}},{key:"item.loan.from_date",fn:function({ value }){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("toHumanDate")(value,'l')))])]}},{key:"item.product.name",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":`/products/${item.product.id}`}},[_vm._v(_vm._s(value))])]}},{key:"item.loan.to_date",fn:function({ value }){return [_c('v-chip',{attrs:{"small":"","color":!_vm.isGreaterThanNow(value) ? 'error' : null}},[_vm._v(_vm._s(_vm._f("toHumanDate")(value,'l')))])]}},{key:"item.loan.to_renewal_date",fn:function({ value }){return [_vm._v(_vm._s(_vm._f("toHumanDate")(value,'l')))]}},{key:"item.status",fn:function({ value }){return [_c('v-chip',{attrs:{"small":"","color":value === 'pending' ? 'info' : value === 'approved' || value === 'extended' ? 'success' : value === 'cancelled' || value === 'denied' ? 'error' : null}},[_vm._v(_vm._s(_vm.$t(value) || '--'))])]}},{key:"item.loan.dates",fn:function({ value }){return [_vm._v(_vm._s(value.length))]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-6"},[_c('h1',[_vm._v("Tutti i prestiti")])])
}]

export { render, staticRenderFns }