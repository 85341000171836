<template>
    <div
    :key="key"
    class="user boxed">
        <!-- statistiche -->
        <!-- ordini -->
        <!-- indirizzi -->
        <!-- tickets -->

        <formizer
        ref="formizer"
        model="User"
        :id="$route.params.id"
        @submit="submit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>{{ method === 'post' ? 'Aggiungi nuovo cliete' : (doc.name.last + ' ' + doc.name.first) }}</h1>
            </template>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #header-menu="{ method }">
                <span v-if="method === 'put'">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>add</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Invia invito per account</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>edit</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Modifica ruolo</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </span>
            </template>

            <template #body="{ fields, card, rules }">
                <v-row>
                    <v-col cols="8">
                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                            </div>

                            <v-text-field
                            v-model="fields.name.first.model"
                            label="* Nome"
                            dense
                            outlined
                            :rules="[rules.required]" />

                            <v-text-field
                            v-model="fields.name.last.model"
                            label="* Cognome"
                            dense
                            outlined
                            :rules="[rules.required]" />

                            <v-text-field
                            v-model="fields.email.model"
                            label="* Email"
                            dense
                            outlined
                            :rules="[rules.required, rules.email]" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Indirizzo principale del cliente</h2>
                            </div>

                            <v-text-field
                            v-model="fields.name.first.model"
                            label="* Nome"
                            dense
                            outlined
                            :rules="[rules.required]" />

                            <v-text-field
                            v-model="fields.name.last.model"
                            label="* Cognome"
                            dense
                            outlined
                            :rules="[rules.required]" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Note</h2>
                            </div>

                            <v-textarea
                            v-model="fields.note.model"
                            label="Note"
                            dense
                            outlined
                            counter />
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top">
                            <v-card class="mb-4">
                                <div class="mb-4">
                                    <v-chip
                                    v-if="fields.roles.model"
                                    class="mr-2"
                                    outlined>{{ !fields.roles.model.length ? 'Cliente' : fields.roles.model }}</v-chip>
                                    <v-chip>{{ fields.status.model || 'actived' }}</v-chip>
                                </div>

                                <div>
                                    <p class="mb-0">{{ fields.email.model }}</p>
                                </div>
                            </v-card>

                            <v-card>
                                <div class="d-flex nowrap align-center mb-4">
                                    <h3>Note</h3>
                                    <v-spacer></v-spacer>
                                    <a
                                    role="button"
                                    class="info--text">Modifica</a>
                                </div>

                                <div>
                                    <div>
                                        <p class="mute--text ma-0 pa-0">Nessuna nota su questo utente</p>
                                    </div>
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'user',
    data () {
        return {
            key: 1
        }
    },
    methods: {
        async submit(method, model, body, id, is_undo, undo) {
            this.$store.commit('loading', true)

            try {
                const doc_id = await this.$api.users.save(id, body)

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') {
                    this.$router.push('/users/' + doc_id)
                }

                this.key++
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>