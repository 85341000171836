var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"files"},[_c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Files")]),_c('div',[_c('v-btn',{on:{"click":function($event){return _vm.$api.patch('v1/migrations/files')}}},[_vm._v("fix files url")])],1)]),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"File","resource":"files","id":"table-files-view","headers":[
        { text: 'ID', value: '_id' },
        { text: 'Immagine', value: 'url' },
        { text: 'Url', value: '_url' },
        { value: 'extension' },
        { value: 'mimetype' }
    ]},scopedSlots:_vm._u([{key:"item.url",fn:function({ value }){return [_c('div',{staticClass:"py-2"},[(value)?_c('v-img',{attrs:{"src":_vm._f("toStaticFile")(value),"max-width":"50"}}):_c('v-img',{attrs:{"src":"/img/no-photo.png","max-width":"50"}})],1)]}},{key:"item._id",fn:function({ item }){return [_vm._v(_vm._s(item.id))]}},{key:"item._url",fn:function({ item }){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(item.url),expression:"item.url"}]},[_vm._v(_vm._s(item.url))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }