var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('v-card',{staticClass:"pa-0"},[_c('bxs-data-table',{ref:"table",attrs:{"model-name":"UserRenew","resource":"users_renews","id":"table-users_renews-view","sort-by":['created_at'],"sort-dir":[true],"hide-default-delete-item":_vm.$api.auth.me.id === 'leila',"static-query":{
            spread: ['user']
        },"headers":[
            { text: 'Data ordine', value: 'created_at' },
            { text: 'Nome', value: 'user.name' },
            { text: 'Numero tessera', value: 'user.card_number' },
            { text: 'Email', value: 'user.email' },
            { text: 'Tipologia rinnovo', value: 'card.name' },
            { text: 'Stato', value: 'payment.status' },
            { text: 'Data rinnovo', value: 'sub_date.label' },
            { text: 'Prezzo tessera', value: 'card.price' }
        ]},scopedSlots:_vm._u([{key:"item.payment.status",fn:function({ value }){return [_c('v-chip',{attrs:{"small":"","outlined":value === 'completed',"color":value === 'completed' ? 'success' : null}},[_vm._v(_vm._s(value))])]}}])})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Rinnovi tessere")])])
}]

export { render, staticRenderFns }