<template>
    <div :class="['bxs-await', { 'bxs-await-loading': loading }]">
        <transition
        name="lazy-trans"
        mode="out-in">
            <v-card v-if="loading">
                <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card>

            <div v-else-if="!loading && data">
                <slot
                :data="data"
                :call="start"
                :error="error" />
            </div>

            <div
            v-else-if="!loading && error"
            class="pa-ui text-center">
                <p class="text-600s">Ops, errore {{ error.status_code }}</p>
                <p class="mb-6">{{ error.message || error.code }}</p>

                <div v-if="$slots.actions">
                    <slot name="actions" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { promiseAllProperties } from '@/assets/libs/utils'

export default {
    name: 'bxs-await',
    props: {
        value: {
            type: [Object, Array, Boolean, String, Function, File],
            required: false,
            default: false
        },
        promises: {
            type: [Function, Object],
            required: false,
            default: null
        },
        delay: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data () {
        return {
            loading: true,
            data: null,
            error: null
        }
    },
    created () {
        this.start()
    },
    methods: {
        start () {
            this.data = null

            if (this.promises) {
                this.error = null

                if (typeof this.promises === 'object') {
                        promiseAllProperties(this.promises).then((res) => {
                        setTimeout(() => {
                            this.$emit('success', res)
                            this.data = res
                            this.loading = false

                            this.$emit('input', res)
                        }, this.delay)
                    }).catch((err) => {
                        setTimeout(() => {
                            this.$emit('error', err)
                            this.error = err
                            this.loading = false
                        }, this.delay)
                    })
                } else {
                    this.promises().then((res) => {
                        setTimeout(() => {
                            this.$emit('success', res)
                            this.data = res
                            this.loading = false

                            this.$emit('input', res)
                        }, this.delay)
                    }).catch((err) => {
                        setTimeout(() => {
                            this.$emit('error', err)
                            this.error = err
                            this.loading = false
                        }, this.delay)
                    })
                }
            }
        },
        play () {
            this.start()
        }
    }
}
</script>

<style lang="scss">
.bxs-await {
    height: 100%;

    > div {
        height: 100%;

        > div {
            height: 100%;
        }
    }
}

.lazy-trans-enter-active,
.lazy-trans-leave-active {
    transition: opacity 0.2s ease, trasform 0.2s ease;
}

.lazy-trans-enter-from,
.lazy-trans-leave-to {
    opacity: 0.1;
    transform: translateX(-5px);
}
</style>