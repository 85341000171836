var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.tooltip,
    placement: 'top',
    delay: {
        show: 200,
        hide: 0
    }
}),expression:"{\n    content: tooltip,\n    placement: 'top',\n    delay: {\n        show: 200,\n        hide: 0\n    }\n}"}],attrs:{"block":_vm.block,"to":_vm.to,"fab":_vm.fab,"icon":_vm.icon,"tile":_vm.tile,"depressed":"","height":_vm.height,"text":_vm.text,"disabled":_vm.disabled,"dark":_vm.dark,"light":_vm.light,"width":_vm.width,"min-height":_vm.minHeight,"min-width":_vm.minWidth,"small":_vm.small,"x-small":_vm.xSmall,"color":_vm.color,"elevation":"0"}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }