import Vue from 'vue'
import moment from 'moment'
import { slugify, parseJwt } from './assets/libs/utils'
import Dinero from 'dinero.js'
import dot from 'dot-object'

moment.locale('it')
Dinero.defaultCurrency = 'EUR'
Dinero.globalLocale = 'it-IT'
Dinero.defaultPrecision = 4

Vue.prototype.$moment = moment

Vue.filter('slugify', v => v ? slugify(v) : v)
Vue.filter('valueFromJwt', (v, k) => v ? dot.pick(k, parseJwt(v)) : v)
Vue.filter('toExpireDateFromJwt', (v) => v ? moment.unix(parseJwt(v).exp).format('lll') : v)

Vue.filter('toHumanDate', (date, type = 'llll') => date ? moment(date).format(type) : '--')
Vue.filter('toHumanCalendar', date => date ? moment(date).calendar() : '--')
Vue.filter('toHumanPrice', (val, s = '') => val && val > 0 ? Dinero({ amount: val }).toFormat() + s : '0,00 €' + s)
Vue.filter('toHumanHttpMethod', (v) => {
    if (v === 'post') return 'Crea'
    else if (v === 'put' || v === 'patch') return 'Modifica'
    else if (v === 'delete') return 'Elimina'
    return v
})
Vue.filter('toStaticFile', (v) => {
    if (!v) return v
    // const env = process.env.NODE_ENV
    // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
    return `${process.env.VUE_APP_API}/${v}`
})

Vue.filter('toThumb', (url, v = 320) => {
    if (!url || !url.length) return '/img/no-photo.png'
    return `${url}?w=${v}`
})

Vue.filter('toHumanImage', (arr) => {
    if (!arr) return '/img/no-photo.png'

    const api = Vue.prototype.$api

    arr = Array.isArray(arr) ? arr : [arr]
    const val = []

    arr.forEach((id) => {
        const file = api.enums.files.find(img => img.id === id)
        if (file) val.push(file)
    })

    if (!val.length) return '/img/no-photo.png'

    return val[0].url
})