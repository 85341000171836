<template>
    <div class="members">
        <div class="mb-8">
            <h1>Tutti i soci</h1>

            <div>
                <v-btn @click="$api.patch('v1/migrations/users')">fix users pwds</v-btn>
            </div>
        </div>

        <!-- to-create-item="/users/new"
        to-update-item="/users" -->
        <bxs-data-table
        ref="table"
        model-name="User"
        resource="users"
        id="table-customers-view"
        :sort-by="['card_number']"
        :sort-dir="[true]"
        :headers="[
            { text: 'ID', value: '_id' },
            { text: 'Nome', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Pwd', value: 'password' },
            { text: 'Numero tessera', value: 'card_number' },
            { text: 'Membro dal', value: 'membership_date' },
            { text: 'Attivo', value: 'is.active_card' },
            { text: 'Servizio attivo', value: 'is.active_service' },
            { text: 'Oggetti di proprietà', value: 'my_product_count' },
            { text: 'Oggetti presi in prestito', value: 'product_borrowed_count' }
        ]"
        :static-filters="$route.query">
            <template #item._id="{ item }">{{ item.id }}</template>

            <template #item.is.membership_date="{ value }">{{ value | toHumanDate }}</template>

            <template #item.is.active_service="{ item, value }">
                <v-chip
                small
                :color="value ? 'success' : null"
                @click="$api.users.modify(item.id, { 'is.active_service': !value })">{{ value ? 'Si' : 'No' }}</v-chip>
            </template>

            <template #item.is.active_card="{ item, value }">
                <v-chip
                small
                :color="value ? 'success' : null"
                @click="$api.users.modify(item.id, { 'is.active_card': !value })">{{ value ? 'Si' : 'No' }}</v-chip>
            </template>

            <template #item.orders="{ value }">{{ value ? value.length : 0 }}</template>

            <template #item.my_product_count="{ item, value }">
                <qty-field
                v-if="$api.auth.is_super"
                v-model="item.my_product_count"
                :min="0"
                dense
                hide-details
                solo
                filled
                :outlined="false"
                single-line
                @change="$api.users.modify(item.id, { my_product_count: $event })"></qty-field>
                <span v-else>{{ value }}</span>
            </template>

            <!-- <template #item-actions="{ id }">
                <v-list-item :to="`/members/${id}`">
                    <v-list-item-icon>
                        <v-icon>web</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Visualizza</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template> -->
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'members',
    methods: {
        setStatus (item, status) {
            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione!',
                text: `
                    Vuoi davvero ${status} questo utente
                    - ${item.email}?
                `,
                next: async () => {
                    await this.$api.users.save(item.id, { status: status })
                    this.$refs.table.getItems()
                }
            })
        }
    }
}
</script>