var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"libraries-view"},[_vm._m(0),_c('v-card',{staticClass:"pa-0"},[_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Library","resource":"libraries","id":"table-libraries-view","to-create-item":"/libraries/new","to-update-item":"/libraries","sort-by":['name'],"sort-dir":[true],"headers":[
            { text: 'Origin ID', value: 'origin_id' },
            { text: 'NNome', value: 'name' },
            { text: 'Dominio', value: 'domain' },
            { text: 'Telefono', value: 'contacts.phone' },
            { text: 'Email', value: 'contacts.email' },
            { text: 'URL', value: 'url' },
            { text: 'Env', value: 'env' },
            { text: 'Disponibile', value: 'is.available' }
        ]},scopedSlots:_vm._u([{key:"item.is.available",fn:function({ item, value }){return [_c('v-chip',{attrs:{"small":"","color":value ? 'success' : null},on:{"click":function($event){_vm.$api.libraries.modify(item.id, { 'is.available': !value }).then(() => _vm.$refs.table.getItems())}}},[_vm._v(_vm._s(value ? 'Si' : 'No'))])]}}])})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Librerie")])])
}]

export { render, staticRenderFns }