<template>
    <div class="histories">
        <div class="mb-8">
            <h1>Logs</h1>
        </div>

        <bxs-data-table
        ref="table"
        model-name="History"
        resource="history"
        id="table-files-view"
        :headers="[
            { text: 'Data', value: '_created_at' },
            { text: 'Library', value: 'library' },
            { text: 'ENV', value: 'env' },

            { text: 'Method', value: 'http_method' },
            { text: 'Endpoint', value: 'endpoint' },
            { text: 'Params', value: 'params' },
            { text: 'Query', value: 'query' },
            { text: 'Body', value: 'body' },
            { text: 'Data', value: 'data' },

            { text: 'Model', value: 'model' },
            { text: 'Ref', value: 'ref' },

            { text: 'User', value: 'user.email' },
            { text: 'Agent', value: 'agent' }
        ]">
            <template #item._created_at="{ item }">{{ item.created_at | toHumanDate('lll') }}</template>

            <template #item.user.email="{ value }">{{ value || '--' }}</template>

            <template #item.http_method="{ value }">{{ value || '--' }}</template>

            <template #item.query="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value || '--' }}</span>
            </template>

            <template #item.params="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value || '--' }}</span>
            </template>

            <template #item.body="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value || '--' }}</span>
            </template>

            <template #item.user.email="{ value }">
                <span v-tooltip="value">{{ value || '--' }}</span>
            </template>

            <template #item.data="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value || '--' }}</span>
            </template>

            <template #item.agent="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value || '--' }}</span>
            </template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'histories',
    data () {
        return {
            // img: ['file_IywhjeXTphlx', 'file_3qttAa2OZuja', 'file_T0eBCnmK55iO']
        }
    }
}
</script>
