<template>
    <div class="form-user">
        <formizer
        ref="formizer"
        model="User"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :id="id"
        @modified="$emit('modified', $event)"
        @submit="intSubmit">
            <template #header-toolbar="{ fields }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>Utente {{ fields.name.last.model }} {{ fields.name.first.model }}</h1>
            </template>

            <template #body="{ method, fields, rules, card, doc }">
                <v-row>
                    <v-col :cols="!fluid ? 8 : 12">
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                            </div>

                            <uploader
                            v-model="fields.img.model"
                            label="Foto profilo"
                            class="uploader-card mb-4" />

                            <v-text-field
                            v-model="fields.name.model"
                            label="* Nome e Cognome"
                            required
                            dense
                            outlined
                            :rules="[rules.required]" />

                            <v-text-field
                            v-model="fields.email.model"
                            label="* Email"
                            required
                            dense
                            outlined
                            :rules="[rules.required, rules.email]" />

                            <v-text-field
                            v-if="method === 'post'"
                            v-model="fields.password.model"
                            label="* Password"
                            required
                            dense
                            outlined
                            :rules="method === 'post' ? [rules.required] : []" />
                        </v-card>

                        <v-card>
                            <div class="mb-8">
                                <h2>Auth</h2>
                            </div>

                            <v-select
                            v-model="fields.status.model"
                            :items="$api.enums.auth.user.status"
                            outlined
                            dense
                            label="Stato"
                            :menu-props="{ top: false, bottom: true, offsetY: true }" />

                            <v-select
                            v-model="fields.role.model"
                            :items="$api.enums.auth.roles"
                            outlined
                            dense
                            clearable
                            label="* Ruolo"
                            required
                            :menu-props="{ top: false, bottom: true, offsetY: true }"
                            :rules="[rules.requiredArr]" />

                            <v-autocomplete
                            v-model="fields.scopes.reads.model"
                            :items="$api.enums.app.scopes.reads"
                            outlined
                            multiple
                            clearable
                            dense
                            label="Blocchi lettura"
                            :menu-props="{ top: false, bottom: true, offsetY: true }" />

                            <v-autocomplete
                            v-model="fields.scopes.writes.model"
                            :items="$api.enums.app.scopes.writes"
                            outlined
                            clearable
                            multiple
                            dense
                            label="Blocchi scrittura"
                            :menu-props="{ top: false, bottom: true, offsetY: true }" />
                        </v-card>

                        <slot
                        :fields="fields"
                        :doc="doc"
                        :card="card"
                        :method="method" />
                    </v-col>

                    <v-col
                    v-if="!fluid"
                    cols="4">
                        <div class="sticky sticky-spacer-top">
                        </div>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
import { form_props } from '@/mixins'

export default {
    name: 'form_user',
    mixins: [form_props],
    methods: {
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>