<template>
    <div class="libraries-view">
        <div class="mb-8">
            <h1>Librerie</h1>
        </div>

        <v-card class="pa-0">
            <bxs-data-table
            ref="table"
            model-name="Library"
            resource="libraries"
            id="table-libraries-view"
            to-create-item="/libraries/new"
            to-update-item="/libraries"
            :sort-by="['name']"
            :sort-dir="[true]"
            :headers="[
                { text: 'Origin ID', value: 'origin_id' },
                { text: 'NNome', value: 'name' },
                { text: 'Dominio', value: 'domain' },
                { text: 'Telefono', value: 'contacts.phone' },
                { text: 'Email', value: 'contacts.email' },
                { text: 'URL', value: 'url' },
                { text: 'Env', value: 'env' },
                { text: 'Disponibile', value: 'is.available' }
            ]">
                <template #item.is.available="{ item, value }">
                    <v-chip
                    small
                    :color="value ? 'success' : null"
                    @click="$api.libraries.modify(item.id, { 'is.available': !value }).then(() => $refs.table.getItems())">{{ value ? 'Si' : 'No' }}</v-chip>
                </template>
            </bxs-data-table>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'libraries'
}
</script>