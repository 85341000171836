<template>
    <div
    :key="key"
    class="product boxed">
        <formizer
        ref="formizer"
        model="Product"
        undo="/products"
        :id="$route.params.id"
        @draw-completed="drawCompleted"
        @submit="submit">
            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>{{ method === 'post' ? 'Aggiungi prodotto' : doc.name }}</h1>
            </template>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #header-menu="{ method, doc }">
                <span v-if="method === 'put'">
                    <v-list-item
                    v-if="method === 'put'"
                    :href="doc.permalink"
                    target="_blank">
                        <v-list-item-icon>
                            <v-icon>language</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Preview</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </span>
            </template>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                body
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #body="{ fields, rules, card, doc }">
                <div v-if="errors">
                    <v-alert
                    type="error"
                    border="left"
                    min-height="80"
                    text
                    outlined>
                        <div class="text-h6">Si è verificato 1 errore per questo prodotto:</div>
                        <div>{{ errors }}</div>
                    </v-alert>
                </div>

                <v-row>
                    <v-col cols="8">
                        <v-card
                        v-if="doc && doc.imgs.length > 0"
                        v-bind="card">
                            <div class="mb-8">
                                <h2>Foto e media</h2>
                            </div>

                            <!-- <uploader
                            ref="uploader"
                            v-model="fields.imgs.model"
                            multiple
                            class="uploader-card" /> -->

                            <v-img
                            v-for="(img, i) in doc.imgs"
                            :key="i"
                            :src="img | toStaticFile"
                            max-width="80" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            base
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                            </div>

                            <v-text-field
                            v-model="fields.name.model"
                            label="* Nome"
                            dense
                            outlined
                            :rules="[rules.required]" />

                            <v-select
                            v-model="fields.library.model"
                            :items="$api.enums.libraries"
                            label="Biblioteca"
                            placeholder="Seleziona la biblioteca"
                            :rules="[rules.required]"
                            dense
                            outlined
                            :menu-props="{ top: false, bottom: true, offsetY: true }" />

                            <v-text-field
                            v-model="fields.code.model"
                            label="Code"
                            dense
                            outlined />

                            <wysiwyg-field v-model="fields.description.model" />
                        </v-card>

                        <!--  ------------------------------------------------------------------------------------------------------------------------------------------------
                            taxonomies
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Tassonomie</h2>
                            </div>

                            <div>
                                <div class="mb-6">
                                    <h4 class="mb-4">Categorie</h4>
                                    <product-categories-field
                                    v-model="fields.categories.model" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        aside
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top">
                            <!--  -->
                            <page-details
                            :src="doc && doc.imgs && doc.imgs.length > 0 ? doc.imgs[0].url : '/img/no-photo.png'"
                            :title="fields.name.model" />

                            <v-card class="pa-4 mb-4">
                                <p class="mb-2">* Stato</p>
                                <v-select
                                v-model="fields.status.model"
                                :items="['available', 'unavailable']"
                                outlined
                                dense
                                solo
                                flat
                                single-line
                                required
                                hide-details
                                :menu-props="{ top: false, bottom: true, offsetY: true }"
                                :rules="[rules.requiredArr]" />
                            </v-card>

                            <v-card class="pa-4">
                                <p class="mb-2">* Stato prestito</p>
                                <v-select
                                v-model="fields.loan.status.model"
                                :items="['available', 'busy', 'maintenance']"
                                outlined
                                dense
                                solo
                                flat
                                single-line
                                required
                                hide-details
                                :menu-props="{ top: false, bottom: true, offsetY: true }"
                                :rules="[rules.requiredArr]" />
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'product',
    data () {
        return {
            key: 1,
            errors: null
        }
    },
    mounted () {
        this.$nextTick(() => {
            window.scrollTo(0, 0)

            this.$el.addEventListener('click', (evt) => {
                console.log('click page', evt)
                this.last_clicked_el = evt.target
            })
        })
    },
    methods: {
        async drawCompleted (method, fields, doc, render) {
            this.$nextTick(() => {
                if (this.last_clicked_el) {
                    this.last_clicked_el.scrollIntoView({
                        block: 'center',
                        inline: 'center',
                        behavior: 'smooth'
                    })
                } else {
                    window.scrollTo(0, 0)
                }
            })

            return render()
        },
        async submit(method, model, body, id, is_undo, undo) {
            console.log('product submit()', method, id, is_undo, undo)
            this.errors = null
            this.$store.commit('loading', true)

            try {
                const doc_id = await this.$api.products.save(id, body)

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') this.$router.push('/products/' + doc_id)
                else this.key++
            } catch (err) {
                this.errors = err.message || err.name || 'Ops, qualcosa è andato storto, riprova tra poco o contatta l\'assistenza'
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>