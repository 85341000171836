<template>
    <div class="bxs-comma-to-list-field">
        <!-- <span class="bxs-comma-to-list-field--label">{{ label }}</span> -->

        <div class="bxs-comma-to-list-field--ac">
            <v-combobox
            v-model="strVal"
            :items="cacheSuggestions"
            :label="label"
            :placeholder="placeholder"
            :dense="dense"
            :hide-details="hideDetails"
            :flat="flat"
            clearable
            :outlined="outlined"
            :single-line="singleLine"
            :filled="filled"
            style="margin-bottom: 6px;"
            @keyup="handleKeyPress">
                <template #append>
                    <v-btn
                    color="primary"
                    :disabled="!strVal"
                    x-small
                    @click="handleKeyPress({ code: 'Comma' })">Salva valore</v-btn>
                </template>
            </v-combobox>

            <p v-if="err" class="red--text my-1" v-html="err"></p>
        </div>

        <draggable
        v-if="arrVals.length > 0"
        v-model="arrVals"
        :ghost-class="arrVals.length > 1 ? 'draggable-ghost' : null"
        :disabled="!draggable && arrVals.length <= 1">
            <v-chip
            v-for="(val, i) in arrVals"
            :key="i"
            class="mr-1"
            small
            :class="{ dragging: arrVals.length > 1 }">
                <v-icon
                v-if="draggable && arrVals.length > 1"
                size="15"
                left>drag_indicator</v-icon>

                <span>{{ val }}</span>

                <!-- <v-text-field
                :value="val"
                dense
                hide-details
                flat
                filled
                single-line
                @input="handleChangeVal($event, i)" /> -->

                <v-icon
                v-if="!hideRemove"
                right
                size="18"
                color="error"
                @click="remove(i)">close</v-icon>
            </v-chip>
        </draggable>
    </div>
</template>

<script>
export default {
    name: 'bxs-comma-to-list-field',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        'hide-remove': {
            type: Boolean,
            required: false,
            default: false
        },
        suggestions: {
            type: Array,
            required: false,
            default: () => []
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        'single-line': {
            type: Boolean,
            required: false,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Valore'
        },
        draggable: {
            type: Boolean,
            required: false,
            default: true
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        flat: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            strVal: null,
            cacheSuggestions: [],
            arrVals: this.value,
            err: false,
            selectedSuggestion: null
        }
    },
    created () {
        if (this.suggestions.length > 0) {
            this.cacheSuggestions = this.suggestions.filter((v) => {
                return !this.arrVals.includes(v)
            }).sort()
        }
    },
    watch: {
        value (newVal) {
            if (Array.isArray(newVal)) this.arrVals = newVal
        },
        selectedSuggestion (newVal) {
            this.pushVal(newVal)
        }
    },
    methods: {
        remove (i) {
            this.arrVals.splice(i, 1)
            this.$emit('input', this.arrVals)
        },
        async handleKeyPress (evt) {
            this.err = false

            if (evt.code === 'Comma' || evt.code === 'Enter') {
                this.strVal = this.strVal.replace(',', '').trim()

                this.pushVal(this.strVal)

                this.$emit('input', this.arrVals)
                this.$emit('add', this.strVal)
            }
        },
        pushVal (value) {
            const badCond = this.err || !value || value === '' || !value.length

            if (badCond) return

            if (this.arrVals.some(v => v.toLowerCase() === value.toLowerCase())) {
                this.err = true
                this.err = `Il valore "${value}" esiste nella lista.`
                return
            }

            this.err = false
            this.arrVals.push(value)
            this.strVal = ''
            this.selectedSuggestion = null

            this.$emit('change', this.arrVals)
        },
        handleChangeVal (val, i) {
            this.arrVals[i] = val
            this.$emit('input', this.arrVals)

            this.$emit('change', this.arrVals)
        },
        clear () {
            this.arrVals = []
            this.$emit('input', this.arrVals)
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-comma-to-list-field {
    position: relative;

    &--ac {
        > div {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }
}
</style>