var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headquarters"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Headquarter","resource":"headquarters","id":"table-headquarters-view","sort-by":['name'],"sort-dir":[false],"headers":[
        { text: 'Immagine', value: 'img.url' },
        { text: 'Nome', value: 'name' },
        { text: 'Biblioteca', value: 'library' },
        { text: 'Indirizzo', value: 'address' },
        { text: 'Giorni addizionali prenotazione', value: 'additional_days' },
        { text: 'Giorndi di apertura', value: 'opening_days' },
        { text: 'Telefono', value: 'support_phone' },
        { text: 'Email', value: 'support_email' },
        { text: 'Orari di apertura', value: 'opening_times' }
    ],"schema":{
        library: {
            instance: 'array',
            query_key: 'library',
            items: _vm.$api.enums.libraries
        }
    },"static-filters":_vm.$route.query},scopedSlots:_vm._u([{key:"item-actions",fn:function({ id }){return [_c('v-list-item',{attrs:{"to":`/members/${id}`}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("web")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Visualizza")])],1)],1)]}},{key:"item.img.url",fn:function({ value }){return [_c('div',{staticClass:"py-2"},[(value)?_c('v-img',{attrs:{"src":_vm._f("toStaticFile")(value),"max-width":"50"}}):_c('v-img',{attrs:{"src":"/img/no-photo.png","max-width":"50"}})],1)]}},{key:"item.orders",fn:function({ value }){return [_vm._v(_vm._s(value ? value.length : 0))]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Tutte le sedi")])])
}]

export { render, staticRenderFns }