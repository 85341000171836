<template>
    <div class="files">
        <div class="mb-8">
            <h1>Files</h1>

            <div>
                <v-btn @click="$api.patch('v1/migrations/files')">fix files url</v-btn>
            </div>
        </div>

        <!-- <uploader
        v-model="img"
        multiple /> -->

        <bxs-data-table
        ref="table"
        model-name="File"
        resource="files"
        id="table-files-view"
        :headers="[
            { text: 'ID', value: '_id' },
            { text: 'Immagine', value: 'url' },
            { text: 'Url', value: '_url' },
            { value: 'extension' },
            { value: 'mimetype' }
        ]">
            <template #item.url="{ value }">
                <div class="py-2">
                    <v-img
                    v-if="value"
                    :src="value | toStaticFile"
                    max-width="50" />

                    <v-img
                    v-else
                    src="/img/no-photo.png"
                    max-width="50" />
                </div>
            </template>

            <template #item._id="{ item }">{{ item.id }}</template>

            <template #item._url="{ item }">
                <span v-tooltip="item.url">{{ item.url }}</span>
            </template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'files',
    data () {
        return {
            // img: ['file_IywhjeXTphlx', 'file_3qttAa2OZuja', 'file_T0eBCnmK55iO']
        }
    }
}
</script>
