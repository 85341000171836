import Vue from 'vue'
import VueRouter from 'vue-router'

import {
    auth,
    nextFactory
} from './middlewares'

import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'
import SigninRecovery from '../views/SigninRecovery.vue'
import SigninReset from '../views/SigninReset.vue'

import Files from '../views/Files.vue'
import Gevents from '../views/Gevents.vue'
import Headquarter from '../views/Headquarter.vue'
import Headquarters from '../views/Headquarters.vue'
import Libraries from '../views/Libraries.vue'
import Library from '../views/Library.vue'
import LibraryStatus from '../views/LibraryStatus.vue'
import Member from '../views/Member.vue'
import MemberStatus from '../views/MemberStatus.vue'
import Members from '../views/Members.vue'
import Order from '../views/Order.vue'
import Orders from '../views/Orders.vue'
import Product from '../views/Product.vue'
import Products from '../views/Products.vue'
import Settings from '../views/Settings.vue'
import SettingsAuths from '../views/children/settings/SettingsAuths.vue'
import User from '../views/User.vue'
import UsersRenews from '../views/UsersRenews.vue'
import Histories from '../views/Histories.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/signin',
        name: 'signin',
        component: Signin,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/signin/recovery',
        name: 'signin_recovery',
        component: SigninRecovery
    },
    {
        path: '/signin/reset',
        name: 'signin_reset',
        component: SigninReset
    },
    {
        path: '*',
        redirect: '/'
    },
    // ----------------------------------------------------------------------------------------------------------------
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------
    {
        path: '/libraries',
        name: 'libraries',
        component: Libraries,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/libraries/:id',
        name: 'library',
        component: Library,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/libraries/:id/status',
        name: 'library_status',
        component: LibraryStatus,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            middlewares: [auth]
        },
        children: [
            {
                path: 'auths',
                name: 'settings_auths',
                component: SettingsAuths,
                meta: {
                    middlewares: [auth]
                }
            }
        ]
    },
    {
        path: '/gevents',
        name: 'gevents',
        component: Gevents,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/histories',
        name: 'histories',
        component: Histories,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/headquarters',
        name: 'headquarters',
        component: Headquarters,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/headquarters/:id',
        name: 'headquarter',
        component: Headquarter,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/members',
        name: 'members',
        component: Members,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/member/:id',
        name: 'member',
        component: Member,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/members/:id/status',
        name: 'member_status',
        component: MemberStatus,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/members_renews',
        name: 'members_renews',
        component: UsersRenews,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/users/:id',
        name: 'user',
        component: User,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/products',
        name: 'products',
        component: Products,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/products/:id',
        name: 'product',
        component: Product,
        meta: {
            middlewares: [auth]
        }
    },
    //
    {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/orders/:id',
        name: 'order',
        component: Order,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/files',
        name: 'files',
        component: Files,
        meta: {
            middlewares: [auth]
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

const ctx = Vue.prototype

router.beforeEach((to, from, next) => {
    console.log('Router beforeEach')

    if (to.name !== 'signin') {
        localStorage.setItem(window.$api.prefix + '_last_page', to.fullPath)
    }

    if (to.meta.middlewares) {
        const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
        const context = { ctx, from, to, next, router }
        const nextMiddleware = nextFactory(context, middlewares, 1)
        const auths = to.meta.authorize ? to.meta.authorize : []
        return middlewares[0](auths, { ...context, next: nextMiddleware })
    }

    return next()
})

export default router