<template>
    <v-menu
    :disabled="disabled"
    offset-y
    close-on-click
    close-on-content-click
    disable-keys
    :right="right"
    :left="left"
    :open-on-hover="openOnHover">
        <template #activator="{ on, attrs }">
            <bxs-btn
            v-on="on"
            v-bind="attrs"
            icon
            tooltip="Menu">
                <v-icon>more_vert</v-icon>
            </bxs-btn>
        </template>

        <v-list
        dense
        nav
        elevation="0">
            <slot />
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'bxs-dot-menu',
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        left: {
            type: Boolean,
            required: false,
            default: false
        },
        right: {
            type: Boolean,
            required: false,
            default: false
        },
        'open-on-hover': {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>