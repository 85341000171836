<template>
    <div class="gevents">
        <div class="mb-8">
            <h1>Schedulazioni</h1>
        </div>

        <!-- <bxs-data-table
        ref="table"
        model-name="Gevent"
        resource="gevents"
        id="table-gevents-view"
        :sort-by="['created_at']"
        :sort-dir="[false]"
        :headers="[
            { text: 'Nome interno', value: 'doc.name' },
            { text: 'Risorsa', value: 'doc.model' },
            { text: 'Documento', value: 'doc.ref' },
            { text: 'Stato inizio', value: 'status.start' },
            { text: 'Stato fine', value: 'status.stop' },
            { text: 'Data inizio', value: 'date.from' },
            { text: 'Data fine', value: 'date.to' }
        ]">
            <template #item.doc.ref="{ value }">
                <router-link :to="'/promos/' + value">{{ value }}</router-link>
            </template>

            <template #item.status.start="{ value }">
                <v-chip :color="value === 'completed' ? 'success' : null">{{ value === 'completed' ? 'Completato' : value }}</v-chip>
            </template>

            <template #item.date.from="{ value }">{{ value | toHumanDate('lll') }}</template>

            <template #item.date.to="{ value }">
                <span v-if="value">{{ value | toHumanDate('lll') }}</span>
                <span v-else>--</span>
            </template>
        </bxs-data-table> -->

        <div class="mt-8">
            <v-sheet
            tile
            height="60"
            class="d-flex align-center">
                <v-btn
                icon
                @click="$refs.calendar.prev()">
                    <v-icon>chevron_left</v-icon>
                </v-btn>

                <v-select
                v-model="type"
                :items="types"
                dense
                outlined
                hide-details
                label="Tipologia"
                class="mx-4"
                style="max-width: 200px;" />

                <v-select
                v-model="mode"
                :items="modes"
                dense
                outlined
                hide-details
                label="Mode"
                class="mr-4"
                style="max-width: 200px;" />

                <!-- <v-select
                v-model="weekday"
                :items="weekdays"
                dense
                outlined
                hide-details
                label="weekdays"
                style="max-width: 200px;" /> -->

                <v-spacer />

                <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()">
                    <v-icon>chevron_right</v-icon>
                </v-btn>
            </v-sheet>

            <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            @change="getEvents"
            @click:event="showEvent" />

            <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x>
                <v-card
                v-if="selectedEvent"
                color="grey lighten-3"
                max-width="400px"
                min-width="200px"
                class="pa-0"
                flat>
                    <v-toolbar
                    color="info"
                    height="50"
                    dark>
                        <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="pa-0 ma-0 pa-4">
                        <v-row>
                            <v-col>
                                <v-chip>{{ selectedEvent.status.start }}</v-chip>

                                <pre style="flex: 1;"><code>{{ selectedEvent.doc.start_setter }}</code></pre>

                                <bxs-date-field
                                v-model="selectedEvent.date.from"
                                :disabled="selectedEvent.status.start !== 'inactive'"
                                select-time
                                cols="12"
                                label="Data inizio" />
                            </v-col>

                            <v-col>
                                <v-chip>{{ selectedEvent.status.stop }}</v-chip>

                                <pre style="flex: 1;"><code>{{ selectedEvent.doc.stop_setter }}</code></pre>

                                <bxs-date-field
                                v-model="selectedEvent.date.to"
                                :disabled="selectedEvent.status.stop !== 'inactive'"
                                select-time
                                cols="12"
                                label="Data fine" />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions class="ma-0 pa-0 px-4 py-2">
                        <v-btn
                        text
                        color="error"
                        @click="deleteEvent(selectedEvent.id)">Elmina</v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                        text
                        color="primary">Salva</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'gevents',
    data () {
        return {
            type: 'month',
            types: ['month', 'week', 'day', '4day'],
            mode: 'stack',
            modes: ['stack', 'column'],
            weekday: [1, 2, 3, 4, 5, 6, 0],
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] }
            ],
            events: [],
            value: '',
            selectedEvent: null,
            selectedElement: null,
            selectedOpen: false,
            status_colors: {
                error: 'error',
                works: 'info',
                completed: 'success',
                inactive: 'grey'
            }
        }
    },
    methods: {
        async getEvents ({ start, end }) {
            const events = []

            const docs = await this.$api.gevents.list({
                pagination: false
            })

            docs.forEach((item) => {
                events.push({
                    name: item.name,
                    start: new Date(item.date.from),
                    end: item.date.to ? new Date(item.date.to) : null,
                    color: this.status_colors[item.status.start],
                    timed: true,
                    doc: item
                })
            })

            this.events = events
        },
        async showEvent ({ nativeEvent, event }) {
            console.log('showEvent', event)

            const doc = await this.$api.gevents.doc(event.doc.id)

            const open = () => {
                this.selectedEvent = doc
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        async deleteEvent (id) {
            try {
                this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                    title: 'Attenzione',
                    text: 'Vuoi eliminare questo evento?',
                    next: async () => {
                        await this.$api.gevents.remove(id)
                        this.getEvents()
                    }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>