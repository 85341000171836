<template>
  <div class="formizer">
      <div
      v-if="loading"
      class="text-center">
        <v-row>
            <v-col cols="12" class="mb-4">
                <v-skeleton-loader type="table-heading"></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="8">
                <v-skeleton-loader type="card-avatar, article, actions" class="mb-6"></v-skeleton-loader>
                <v-skeleton-loader type="date-picker"></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="4">
                <v-skeleton-loader type="article, actions" class="mb-6"></v-skeleton-loader>
                <v-skeleton-loader type="table-heading, list-item-two-line, image, table-tfoot"></v-skeleton-loader>
            </v-col>
        </v-row>
    </div>

    <div v-else>
        <v-row v-if="debug">
            <v-col
            v-for="field in fields"
            :key="field.name"
            cols="4">
                <code>{{ field.name }}: {{ field.model }}</code>
            </v-col>
        </v-row>

        <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submit">
            <!-- ---------------------------------------------------------------------------------------------------------------
                header
            --------------------------------------------------------------------------------------------------------------- -->
            <div
            v-if="!hideHeader"
            class="mb-4">
                <v-row class="formizer--header">
                    <v-col cols="6">
                        <slot
                        name="header-toolbar"
                        :id="cacheId"
                        :method="method"
                        :rules="rules"
                        :doc="doc"
                        :fields="fieldsObj" />
                    </v-col>

                    <v-col
                    cols="6"
                    class="text-right">
                        <bxs-dot-menu>
                            <slot
                            name="header-menu"
                            :method="method"
                            :id="cacheId"
                            :doc="doc"
                            :fields="fieldsObj" />

                            <v-list-item
                            v-if="method === 'put'"
                            @click="deleteDocs">
                                <v-list-item-icon>
                                    <v-icon>delete</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Elimina</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </bxs-dot-menu>

                        <v-btn
                        :disabled="!forceEmit && !modified"
                        color="primary"
                        class="ml-3 mr-1"
                        @click="submit(false)">Salva</v-btn>

                        <v-btn
                        color="primary"
                        :disabled="!forceEmit && !modified"
                        @click="submit(true)">Salva ed esci</v-btn>
                    </v-col>
                </v-row>
            </div>

            <!-- ---------------------------------------------------------------------------------------------------------------
                body
            --------------------------------------------------------------------------------------------------------------- -->
            <slot
            name="body"
            :fields="fieldsObj"
            :rules="rules"
            :card="{
              class: ['mb-6', 'formizer--card'], // grey lighten-5
            }"
            :model="model"
            :doc="doc"
            :modified="modified"
            :id="cacheId"
            :method="method"
            :on="{ click: submit }" />
        </v-form>

        <!-- ---------------------------------------------------------------------------------------------------------------
            footer layout
        --------------------------------------------------------------------------------------------------------------- -->
        <v-divider v-if="!hideFooter" class="my-2"></v-divider>

        <v-footer
        v-if="!hideFooter"
        height="60"
        style="box-shadow: none !important;">
            <v-btn
            v-if="method === 'put'"
            color="error"
            @click="deleteDocs">Elimina</v-btn>

            <v-spacer />

            <v-btn
            :disabled="!forceEmit && !modified"
            color="primary"
            @click="submit(false)">Salva</v-btn>
        </v-footer>

        <!-- ---------------------------------------------------------------------------------------------------------------
            footer app
        --------------------------------------------------------------------------------------------------------------- -->
        <v-footer
        v-if="!hideFooter"
        inset
        :app="footerApp"
        color="#fff"
        clipped-right
        clipped-left
        height="60">
            <v-btn
            v-if="undo"
            text
            @click="$router.go(-1)">
                <v-icon left>chevron_left</v-icon>
                <span>indietro</span>
            </v-btn>

            <v-spacer />

            <slot
            name="footer-buttons"
            :modified="modified" />

            <v-btn
            :disabled="!forceEmit && !modified"
            class="mr-2"
            color="primary"
            @click="submit(false)">Salva</v-btn>

            <v-btn
            v-if="!hideUndo"
            :disabled="!forceEmit && !modified"
            color="primary"
            @click="submit(true)">Salva ed esci</v-btn>
        </v-footer>

        <!--  -->
        <slot name="append" />
    </div>
  </div>
</template>

<script>
import dotObject from 'dot-object'

function parseDotNotation (str, val, obj) {
  let currentObj = obj
  const keys = str.split('.')
  let i
  const l = Math.max(1, keys.length - 1)
  let key

  for (i = 0; i < l; ++i) {
    key = keys[i]
    currentObj[key] = currentObj[key] || {}
    currentObj = currentObj[key]
  }

  currentObj[keys[i]] = val
  delete obj[str]
}

Object.expand = (obj) => {
  for (const key in obj) {
    if (key.indexOf('.') !== -1) parseDotNotation(key, obj[key], obj)
  }
  return obj
}

export default {
  name: 'formizer',
  props: {
    id: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    },
    model: {
      type: String,
      required: true
    },
    populate: {
      type: String,
      required: false,
      default: null
    },
    debug: {
      type: Boolean,
      required: false,
      default: false // process.env.NODE_ENV === 'development'
    },
    items: {
      type: Object,
      required: false,
      default: null
    },
    undo: {
      type: String,
      required: false,
      default: null
    },
    'hide-header': {
        type: Boolean,
        required: false,
        default: false
    },
    'hide-footer': {
      type: Boolean,
      required: false,
      default: false
    },
    'hide-undo': {
        type: Boolean,
      required: false,
      default: false
    },
    'force-emit': {
        type: Boolean,
        required: false,
        default: false
    },
    'footer-app': {
        type: Boolean,
        required: false,
        default: true
    }
  },
  computed: {
    fieldsObj () {
      const o = {}
      this.fields.forEach((item) => (o[item.name] = item))
      return dotObject.object(o)
    },
    method () {
      return this.cacheId === 'new' || !this.cacheId ? 'post' : 'put'
    },
    modified_fields () {
        return this.fields.filter(v => v.modified)
    }
  },
  data () {
    return {
      rules: {
        alphanumeric: (v) => /^[a-z\d]*$/.test(v) || 'codice in maiuscolo, alfanumerico, senza spazio',
        capitalize: (v) => /^[A-Z]$/.test(v) || 'campo con lettere maiuscole',
        capitalizeFirstletter: (v) => /([^A-Z])([A-Z])(?=[A-Z]{2})|^([A-Z])/.test(v) || 'Questo campo deve avere la prima lettera maiuscola',
        dimension: (v) => /^\d+(?:[.]\d{1,3}|$)$/.test(v) || 'campo con 3 decimali',
        email: (v) => /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(v) || 'inserisci un email corretta',
        fiscalcode: (v) => /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/.test(v) || 'inserisci un codice fiscale corretto',
        from0To100: (v) => /^0*(?:[1-9][0-9]?|100)$/g.test(v) || 'questo campo deve essere compreso tra 0 e 100',
        least1Number: (v) => /\d+/.test(v) || 'questo campo deve contenere almeno un numero',
        max1000char: (v) => (v && v.length > 1000) || 'questo campo non può superare i 1000 caratteri',
        max165char: (v) => (v && v.length < 165) || 'questo campo non può superare i 165 caratteri',
        max20char: (v) => (v && v.length > 20) || 'questo campo non può superare i 20 caratteri',
        min100char: (v) => (v && v.length < 100) || 'questo campo deve contenere almeno 100 caratteri',
        min165char: (v) => (v && v.length > 165) || 'questo campo deve contenere almeno 165 caratteri',
        only5Numbers: (v) => /^[0-9]{5}$/.test(v) || 'questo campo deve contere solo 5 numeri',
        onlyNumbers: (v) => /^\d+$/.test(v) || 'questo campo deve contere solo da numeri',
        phone: (v) => /^\d{3,}$/.test(v) || 'il campo richiede solo numeri',
        required: (v) => !!v || 'Campo richiesto',
        requiredArr: (v) => (v && !!v.length) || 'Campo richiesto',
        threeDecimal: (v) => /^\d+(?:[.]\d{1,3}|$)$/.test(v) || 'campo con 3 decimali',
        twoDecimal: (v) => /^\d+(?:[.]\d{1,2}|$)$/.test(v) || 'campo con 2 decimali',
        vatnumber: (v) => /^[0-9]{11}$/.test(v) || 'inserisci una partita iva corretta'
      },
      valid: false,
      doc: null,
      loading: true,
      paths: null,
      modified: false,
      fields: [],
      cacheId: this.id
    }
  },
//   created () {
//     if (!this.cacheId) {
//       if (this.$route.params.id && this.$route.params.id.length > 0) {
//         this.cacheId = this.$route.params.id
//       }
//     }
//   },
  async mounted () {
    await this.$nextTick()

    // eslint-disable-next-line
    if (!this.$listeners.hasOwnProperty('before-draw-start')) {
      this.$on('before-draw-start', (next) => next())
    }

    // eslint-disable-next-line
    if (!this.$listeners.hasOwnProperty('draw-completed')) {
      this.$on('draw-completed', (method, fieldObj, doc, render) => render())
    }

    // eslint-disable-next-line
        if (!this.$listeners.hasOwnProperty('before-submit')) {
      this.$on('before-submit', (fieldObj, next) => next())
    }

    await this.draw()
  },
  watch: {
    model (newVal, oldVal) {
        console.log('formizer watch model', newVal, oldVal)

        if (newVal === oldVal) return

        this.draw().then(() => {
            this.loading = false
        })
    },
    fields: {
      immediate: false,
      deep: true,
      handler (newVal) {
        newVal.forEach((field) => {
          field.modified = JSON.stringify(field.model) !== JSON.stringify(field.oldModel)
        })

        this.modified = this.fields.some((field) => field.modified)

        this.$emit('modified', this.modified)
      }
    }
  },
  methods: {
    draw () {
        console.log('formizer draw')
        this.loading = true
        // this.$store.commit('loading', true)

      return new Promise((resolve, reject) => {
        this.$emit('before-draw-start', async () => {
          try {
            // const schema = await this.$api.getSchema(this.model)
            const schema = this.$api.enums.app.schemes[this.model]

            this.fields = this._genFields(schema)

            // set update
            if (this.cacheId && this.cacheId.length > 0 && this.cacheId !== 'new') {
              await this.setUpdate()
            }

            await this.$nextTick()
            await this.drawCompleted()

            return resolve
          } catch (err) {
            reject(err)
            return console.error(err)
          }
        })
      })
    },
    drawCompleted () {
        console.log('formizer drawCompleted')

      return new Promise((resolve, reject) => {
        this.$emit(
            'draw-completed',
            this.method,
            this.fieldsObj,
            this.doc,
            async () => {
                this.loading = false
                // this.$store.commit('loading', false)
                return resolve
            }
        )
      })
    },
    // private ------------------------------------------------------------------------------------------------------------------------------------------------
    _genFields (dotSchema, cb) {
      const fields = []
      this.paths = dotSchema

      Object.keys(this.paths).forEach((key) => {
        // if (key === '_id' || key === 'id' || key === 'oldId' || key === 'oldid' || key === 'old_id') return
        const field = this.paths[key]
        const obj = this._genField(field)
        fields.push(obj)
      })

      return fields
    },
    _genField (field) {
      const val = field.defaultValue
      // campo già presente
      const _field = this.getField(field.path)

      const obj = {
        disabled: false,
        enumValues: field.enumValues || null,
        label: field.path,
        oldModel: val ? JSON.parse(JSON.stringify(val)) : val,
        model: _field ? _field.model : val,
        name: field.path,
        nativeType: field.instance,
        type: field.instance.toLowerCase(),
        placeholder: field.path,
        required: field.isRequired,
        rules: field.isRequired ? [this.rules.required] : [],
        modified: _field ? _field.modified : false,
        touched: false,
        options: field.options
      }

      this.$emit('generate-field-completed', obj)

      return obj
    },
    _genBody () {
      const obj = {}

      this.fields.forEach((field) => {
        if (field.modified) obj[field.name] = field.model
      })

      return Object.keys(obj).length > 0 ? obj : null
    },
    // publics ------------------------------------------------------------------------------------------------------------------------------------------------
    async setUpdate () {
      const doc = await this.$api.fillSchema(
        this.model,
        this.cacheId,
        this.populate
      )

      if (!doc) return this.doc

      this.doc = doc

      dotObject.keepArray = true
      const paths = dotObject.dot(this.doc)

      this.fields.forEach((item) => {
        item.oldModel = paths[item.name] !== undefined ? JSON.parse(JSON.stringify(paths[item.name])) : paths[item.name]
        item.model = paths[item.name]
      })

      return doc
    },
    setModified (bool) {
      this.modified = bool
    },
    getField (key) {
      return this.fields.find((item) => item.name === key)
    },
    // valorize select values
    valorizeEnums (obj) {
      if (!obj) return

      Object.keys(obj).forEach((key) => {
        const field = this.getField(key)

        if (!field) {
          console.warn('FORMIZER valorizeEnums() => field "' + key + '" not found!', 'doc:')
        } else {
          field.enumValues = obj[key]
        }
      })
    },
    valorize (obj) {
      if (!obj) return

      Object.keys(obj).forEach((key) => {
        const field = this.getField(key)

        if (!field) {
          console.warn('FORMIZER valorize() => field "' + key + '" not found!')
        } else {
          field.model = obj[key]
        }
      })
    },
    disable (keys, value = true) {
      keys.forEach((key) => {
        const field = this.getField(key)
        if (!field) console.warn('FORMIZER disable() => field "' + key + '" not found!')
        else field.disabled = value
      })
    },
    getDoc () {
      return this.doc
    },
    // events ------------------------------------------------------------------------------------------------------------------------------------------------
    deleteDocs() {
        this.$store.commit('SET_APP_DIALOG_CONFIRM', {
            title: 'Attenzione',
            text: 'Vuoi eliminare davvero questa risorsa?',
            next: async () => {
                this.$store.commit('loading', true)

                try {
                    await dotObject.pick(this.resource, this.$api).remove(this.cacheId)
                    this.$router.go(-1)
                } catch (err) {
                    throw new Error(err.message || err.name)
                } finally {
                    this.$store.commit('loading', false)
                }
            }
        })
    },
    submit (isUndo) {
      if (!this.$refs.form.validate()) {
        // return this.$notify({
        //   text: 'Attenzione ai i campi richiesti',
        //   type: 'warn'
        // })
        this.$emit('unvalid')
        return this.$toast.warning('Attenzione ai i campi richiesti!')
      }

      return new Promise((resolve) =>
        resolve(
          this.$emit('before-submit', this.fieldsObj, async () => {
            const body = this._genBody()
            console.log('formizer submit', body)
            this.$eventHub.$emit('formizer-submit')

            if (this.forceEmit || body) {
                return this.$emit(
                    'submit',
                    this.method,
                    this.model,
                    body,
                    this.cacheId === 'new' ? null : this.cacheId,
                    isUndo,
                    this.undo
                )
            }

            // return this.$notify({ text: 'Operazione completata', type: 'success' })
          })
        )
      )
    }
  },
  beforeDestroy () {
    this.$eventHub.$off('formizer-submit')
    this.$eventHub.$off('formizer-modified')
  }
}
</script>

<style scoped>
.formizer {
  padding-bottom: 25vh;
}
</style>