<template>
    <div class="headquarters">
        <div class="mb-8">
            <h1>Tutte le sedi</h1>
        </div>

        <!-- to-create-item="/users/new"
        to-update-item="/users" -->
        <bxs-data-table
        ref="table"
        model-name="Headquarter"
        resource="headquarters"
        id="table-headquarters-view"
        :sort-by="['name']"
        :sort-dir="[false]"
        :headers="[
            { text: 'Immagine', value: 'img.url' },
            { text: 'Nome', value: 'name' },
            { text: 'Biblioteca', value: 'library' },
            { text: 'Indirizzo', value: 'address' },
            { text: 'Giorni addizionali prenotazione', value: 'additional_days' },
            { text: 'Giorndi di apertura', value: 'opening_days' },
            { text: 'Telefono', value: 'support_phone' },
            { text: 'Email', value: 'support_email' },
            { text: 'Orari di apertura', value: 'opening_times' }
        ]"
        :schema="{
            library: {
                instance: 'array',
                query_key: 'library',
                items: $api.enums.libraries
            }
        }"
        :static-filters="$route.query">
            <template #item-actions="{ id }">
                <v-list-item :to="`/members/${id}`">
                    <v-list-item-icon>
                        <v-icon>web</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Visualizza</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template #item.img.url="{ value }">
                <div class="py-2">
                    <v-img
                    v-if="value"
                    :src="value | toStaticFile"
                    max-width="50" />

                    <v-img
                    v-else
                    src="/img/no-photo.png"
                    max-width="50" />
                </div>
            </template>

            <template #item.orders="{ value }">{{ value ? value.length : 0 }}</template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'headquarters'
}
</script>