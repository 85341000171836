var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"histories"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"History","resource":"history","id":"table-files-view","headers":[
        { text: 'Data', value: '_created_at' },
        { text: 'Library', value: 'library' },
        { text: 'ENV', value: 'env' },

        { text: 'Method', value: 'http_method' },
        { text: 'Endpoint', value: 'endpoint' },
        { text: 'Params', value: 'params' },
        { text: 'Query', value: 'query' },
        { text: 'Body', value: 'body' },
        { text: 'Data', value: 'data' },

        { text: 'Model', value: 'model' },
        { text: 'Ref', value: 'ref' },

        { text: 'User', value: 'user.email' },
        { text: 'Agent', value: 'agent' }
    ]},scopedSlots:_vm._u([{key:"item._created_at",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("toHumanDate")(item.created_at,'lll')))]}},{key:"item.user.email",fn:function({ value }){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(value),expression:"value"}]},[_vm._v(_vm._s(value || '--'))])]}},{key:"item.http_method",fn:function({ value }){return [_vm._v(_vm._s(value || '--'))]}},{key:"item.query",fn:function({ value }){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value || '--'))])]}},{key:"item.params",fn:function({ value }){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value || '--'))])]}},{key:"item.body",fn:function({ value }){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value || '--'))])]}},{key:"item.data",fn:function({ value }){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value || '--'))])]}},{key:"item.agent",fn:function({ value }){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value || '--'))])]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Logs")])])
}]

export { render, staticRenderFns }