<template>
    <div class="settings">
        <v-row>
            <v-col cols="3">
                <v-card class="pa-0 overflow-hidden">
                    <div
                    class="d-flex flex-column justify-center"
                    style="height: 70px; padding: 0 1.5rem;">
                        <h4 class="mb-1">{{ $api.auth.me.email }}</h4>
                        <a :href="$api.enums.origins.app">{{ $api.enums.origins.app }}</a>
                    </div>

                    <v-divider></v-divider>

                    <bxs-data-lazy :promises="() => $api.settings.list()">
                        <template #default="{ data }">
                            <v-list class="pa-0 ma-0">
                                <v-list-item
                                v-for="item in data"
                                :key="item.id"
                                :to="item.to"
                                :disabled="item.disabled">
                                    <v-list-item-icon>
                                        <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </template>
                    </bxs-data-lazy>
                </v-card>
            </v-col>

            <v-col cols="9">
                <div
                v-if="$route.name === 'settings'"
                style="padding-top: 70px;">
                    <h1 class="mb-2">Impostazioni</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. <br> Ipsum animi debitis dignissimos eligendi, reiciendis quibusdam repellat enim repudiandae corrupti odit esse, laboriosam quidem totam! Vitae voluptatibus corporis modi aliquam rerum?</p>
                </div>

                <!--  -->
                <!-- <div v-if="$api.auth.is_super">
                    <ul>
                        <li>
                            <v-btn @click="$api.post('v1/migrations/suppliers')">1) Import fornitori</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.post('v1/migrations/materials')">2) Import materiali</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.post('v1/migrations/materials_sizes')">3) Import taglie materiali</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.post('v1/migrations/borders')">4) Import bordi</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.post('v1/migrations/borders_colors')">5) Import colori bordi</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.post('v1/migrations/products')">6) Import prodotti</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.patch('v1/migrations/products_imgs')">7) Sync immagini prodotti</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.patch('v1/migrations/users')">8) Import utenti</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.patch('v1/migrations/users_addresses')">9) Import indirizzi utenti</v-btn>
                        </li>
                        <li>
                            <v-btn @click="$api.patch('v1/migrations/orders')">10) Import ordini</v-btn>
                        </li>
                    </ul>
                </div> -->

                <router-view />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'settings',
    data () {
        return {
        }
    }
}
</script>