<template>
    <div>
        <!-- :search-input.sync="keywords" -->
        <v-autocomplete
        v-model="selected"
        :disabled="disabled"

        :item-text="itemText"
        :item-value="itemValue"
        :cache-items="false"
        :items="items"
        :return-object="false"
        :multiple="multiple"

        :label="required ? '* ' + label : label"
        :placeholder="placeholder"

        :menu-props="{
            closeOnClick: false,
            closeOnContentClick: false,
            disableKeys: true,
            openOnClick: false,
            maxHeight: 200,
            offsetY: true,
            offsetOverflow: true,
            transition: false,
            top: false,
            bottom: true
        }"

        :hide-selected="false"
        :hide-no-data="false"
        :no-filter="true"
        :hide-details="hideDetails"

        :loading="loading"
        allow-overflow
        :clearable="clearable"
        filled
        dense
        :rules="rules"
        :chips="multiple"
        :deletable-chips="multiple"
        :small-chips="smallChips"

        @change="$emit('change', $event)">
            <template #item="{ item }">
                <span
                class="d-flex align-center"
                style="width: 100%;">
                    <span>{{ dot.pick(itemText, item) }}</span>
                </span>
            </template>

            <template
            v-if="!hideAdd"
            #append-outer>
                <!-- min-width="200px" -->
                <v-btn
                depressed
                style="margin-top: -8px;"
                @click="setForm()">
                    <v-icon>add</v-icon>
                    <!-- <span>{{ labelAdd }}</span> -->
                </v-btn>
            </template>
        </v-autocomplete>

        <v-dialog
        v-if="!hideAdd"
        v-model="form.on"
        persistent
        right
        scrollable
        width="768">
            <v-card>
                <v-card-title>
                    <v-toolbar>
                        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-btn
                        icon
                        @click="form.on = null">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card-title>

                <v-card-text>
                    <rapid-dep
                    :key="form.on"
                    ref="form"
                    :id="form.id"
                    :type="type"
                    hide-header
                    hide-footer
                    @submit="submit" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                    color="primary"
                    @click="$refs.form.submit()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import RapidDep from '@/components/forms/RapidDep.vue'
import dot from 'dot-object'

export default {
    name: 'dep-field',
    components: {
        // 'rapid-dep': RapidDep
    },
    props: {
        value: {
            type: [Array, String],
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        'label-add': {
            type: String,
            required: false,
            default: 'Nuova'
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        'item-text': {
            type: String,
            required: false,
            default: 'path.it'
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        'small-chips': {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: 'category'
        },
        'dialog-title': {
            type: String,
            required: false,
            default: null
        },
        'hide-add': {
            type: Boolean,
            required: false,
            default: false
        },
        exclude: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            dot,
            selected: this.value,
            items: [],
            keywords: null,
            loading: false,

            form: {
                on: null,
                id: null
            }
        }
    },
    created () {
        this.start()
    },
    watch: {
        value (newVal) {
            this.selected = newVal
        },
        selected (newVal) {
            this.$emit('input', newVal)
        }
    },
    methods: {
        async start () {
            const filters = {
                type: '=|' + this.type
            }

            if (this.exclude.length > 0) {
                filters.id = '<>|' + this.exclude.join(',')
            }

            this.items = await this.$api.deps.list({
                pagination: false,
                sort: { 'path.it': 1 },
                filters: filters
            })
        },
        setForm (id) {
            this.form.id = id || null
            this.form.on = !this.form.on
        },
        async submit (method, model, body, id) {
            try {
                const docId = await this.$api.deps.save(id, body)
                const doc = await this.$api.deps.doc(docId)

                if (doc) {
                    this.selected = doc
                    this.items = [this.selected]
                }

                this.form.id = null
                this.form.on = false
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
</style>