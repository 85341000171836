<template>
    <div class="dep boxed">
        <formizer
        :key="key"
        ref="formizer"
        model="Library"
        :id="$route.params.id"
        @draw-completed="drawCompleted"
        @submit="submit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>{{ method === 'post' ? 'Aggiungi biblioteca' : doc.name }}</h1>
            </template>

            <template #body="{ fields, rules, card, doc, method }">
                <div v-if="errors.length > 0">
                    <v-alert
                    type="error"
                    border="left"
                    min-height="80"
                    text
                    outlined>
                        <div class="text-h6">Si è verificato {{ errors.length }} errore:</div>
                        <div v-for="(v, i) in errors" :key="i">{{ v }}</div>
                    </v-alert>
                </div>

                <v-row>
                    <v-col cols="8">
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                            </div>

                            <div>
                                <v-text-field
                                v-model="fields.origin_id.model"
                                label="* ID Zoho"
                                required
                                dense
                                :readonly="method === 'put'"
                                outlined
                                :rules="[rules.required]" />

                                <v-text-field
                                v-model="fields.name.model"
                                label="* Nome"
                                required
                                dense
                                outlined
                                :rules="[rules.required]" />

                                <v-text-field
                                v-model="fields.domain.model"
                                label="* Dominio"
                                required
                                dense
                                prefix="https://"
                                suffix=".libreria.space"
                                :readonly="method === 'put'"
                                :loading="name.loading"
                                outlined
                                :rules="[rules.required]"
                                @input="handleInputDomain"
                                @change="handleChangeDomain" />

                                <comma-to-list-field
                                v-model="fields.alias_domains.model"
                                label="Domini alias" />

                                <v-alert
                                v-if="name.error"
                                border="left"
                                warning
                                dense
                                text>Attenzione, una biblioteca esiste con questo nome <b>"{{ fields.name.model }}"</b></v-alert>

                                <v-text-field
                                v-model="fields.address.model"
                                label="Indirizzo"
                                required
                                hide-details
                                dense
                                outlined />
                            </div>
                        </v-card>

                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Contatti</h2>
                            </div>

                            <v-text-field
                            v-model="fields.contacts.phone.model"
                            label="Numero di telefono"
                            required
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.contacts.email.model"
                            label="E-mail"
                            required
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.website.model"
                            label="Sito della Biblioteca"
                            required
                            dense
                            hide-details
                            outlined />
                        </v-card>

                         <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Zoho</h2>
                            </div>

                            <v-text-field
                            v-model="fields.zoho.org_id.model"
                            label="Org ID"
                            required
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.zoho.client_id.model"
                            label="Client ID"
                            required
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.zoho.client_secret.model"
                            label="Client secret"
                            required
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.zoho.scopes.model"
                            label="Scopes"
                            required
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.zoho.access.refresh_token.model"
                            label="Refresh access token"
                            required
                            dense
                            outlined />
                        </v-card>
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        aside
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top">
                            <!--  -->
                            <page-details
                            :src="doc && doc.imgs && doc.imgs.length > 0 ? doc.imgs[0].url : '/img/no-photo.png'"
                            :title="doc ? doc.name : null"
                            :permalink="doc ? doc.url : null" />
                        </div>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
import { slugify } from '@/assets/libs/utils'

export default {
    name: 'library',
    data () {
        return {
            key: 0,
            name: {
                loading: false,
                error: null
            },
            errors: []
        }
    },
    methods: {
        handleInputDomain (v) {
            this.$refs.formizer.valorize({
                domain: slugify(v),
                url: `https://${slugify(v)}.libreria.space`
            })
        },
        handleChangeDomain (v) {
            this.name.loading = true
            this.name.error = null

            this.$api.get('v1/schemes/Library/exists', {
                domain: slugify(v)
            }).then((value) => {
                this.name.error = !!value
                this.name.loading = false
            }).catch((err) => {
                this.name.error = err
                this.name.loading = false
            })
        },
        drawCompleted (method, fields, doc, render) {
            render()
        },
        async submit(method, model, body, id, is_undo, undo) {
            this.$store.commit('loading', true)

            try {
                // create library
                const doc_id = await this.$api.libraries.save(id, body)

                // // install db
                // await this.$api.post('/v1/libraries/' + doc.id + '/database')
                // // install api
                // await this.$api.post('/v1/libraries/' + doc.id + '/api')
                // // install hub
                // await this.$api.post('/v1/libraries/' + doc.id + '/hub')
                // install webapp
                // await this.$api.post('/v1/libraries/' + doc_id + '/webapp')

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') this.$router.push('/libraries/' + doc_id)
                else this.key++
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>