import dot from 'dot-object'

export const form_props = {
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        },
        fluid: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            formizer: {
                key: 1,
                errors: null
            }
        }
    }
}

export function rapidForm(resource) {
    return {
        data() {
            return {
                form: {
                    on: false,
                    id: null,
                    resource: resource,
                    modified: false
                }
            }
        },
        methods: {
            setForm(id) {
                this.form.id = id || null
                this.form.on = !this.form.on
            },
            async submit(method, model, body, id) {
                this.$store.commit('loading', true)

                try {
                    await dot.pick(this.form.resource, this.$api).save(id, body)
                    this.setForm()

                    if (this.$refs.table && this.$refs.table.getItems) {
                        this.$refs.table.getItems()
                    }
                } catch (err) {
                    throw new Error(err.message || err.name)
                } finally {
                    this.$store.commit('loading', false)
                }
            }
        }
    }
}