import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import it from 'vuetify/lib/locale/it'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { it },
        current: 'it'
    },
    icons: {
        iconfont: 'md'
    },
    theme: {
        dark: false,
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#000',
                secondary: '#ffbb36',
                tertiary: '#4F8D9F',
                fourth: '#272727',
                background: '#f6f6f7',
                highlight: '#E9B760',
                // accent: '#0097A7',
                info: '#0096ff',
                // error: '#FF5252',
                // warning: '#F9A825',
                mute: '#666666'
            },
            dark: {
                primary: '#000',
                secondary: '#60981a',
                tertiary: '#487213',
                fourth: '#304c0d',
                background: '#f6f6f7',
                // accent: '#0097A7',
                info: '#0096ff',
                // error: '#FF5252',
                // warning: '#F9A825',
                mute: '#666666'
            }
        }
    }
})
