var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"members"},[_c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Tutti i soci")]),_c('div',[_c('v-btn',{on:{"click":function($event){return _vm.$api.patch('v1/migrations/users')}}},[_vm._v("fix users pwds")])],1)]),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"User","resource":"users","id":"table-customers-view","sort-by":['card_number'],"sort-dir":[true],"headers":[
        { text: 'ID', value: '_id' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Pwd', value: 'password' },
        { text: 'Numero tessera', value: 'card_number' },
        { text: 'Membro dal', value: 'membership_date' },
        { text: 'Attivo', value: 'is.active_card' },
        { text: 'Servizio attivo', value: 'is.active_service' },
        { text: 'Oggetti di proprietà', value: 'my_product_count' },
        { text: 'Oggetti presi in prestito', value: 'product_borrowed_count' }
    ],"static-filters":_vm.$route.query},scopedSlots:_vm._u([{key:"item._id",fn:function({ item }){return [_vm._v(_vm._s(item.id))]}},{key:"item.is.membership_date",fn:function({ value }){return [_vm._v(_vm._s(_vm._f("toHumanDate")(value)))]}},{key:"item.is.active_service",fn:function({ item, value }){return [_c('v-chip',{attrs:{"small":"","color":value ? 'success' : null},on:{"click":function($event){return _vm.$api.users.modify(item.id, { 'is.active_service': !value })}}},[_vm._v(_vm._s(value ? 'Si' : 'No'))])]}},{key:"item.is.active_card",fn:function({ item, value }){return [_c('v-chip',{attrs:{"small":"","color":value ? 'success' : null},on:{"click":function($event){return _vm.$api.users.modify(item.id, { 'is.active_card': !value })}}},[_vm._v(_vm._s(value ? 'Si' : 'No'))])]}},{key:"item.orders",fn:function({ value }){return [_vm._v(_vm._s(value ? value.length : 0))]}},{key:"item.my_product_count",fn:function({ item, value }){return [(_vm.$api.auth.is_super)?_c('qty-field',{attrs:{"min":0,"dense":"","hide-details":"","solo":"","filled":"","outlined":false,"single-line":""},on:{"change":function($event){return _vm.$api.users.modify(item.id, { my_product_count: $event })}},model:{value:(item.my_product_count),callback:function ($$v) {_vm.$set(item, "my_product_count", $$v)},expression:"item.my_product_count"}}):_c('span',[_vm._v(_vm._s(value))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }