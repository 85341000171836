<template>
    <v-navigation-drawer
    v-model="$store.state.is_open_menu"
    app
    clipped
    open-on-hover
    width="240">
        <!-- <template #prepend>
            <v-list-item
            style="height: 60px;"
            class="my-0 py-0">
                <v-avatar
                size="30"
                color="grey"
                class="mr-4 white--text">{{ $api.auth.me.name.first[0] }}</v-avatar>

                <v-list-item-content class="truncated">
                    <v-list-item-title class="mb-0">{{ $api.auth.me.name.first }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $api.auth.me.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-divider class="ma-0 pa-0"></v-divider> -->

        <v-list dense>
            <!-- home -->
            <v-list-item to="/">
                <v-list-item-icon>
                    <v-icon>home</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- orders -->
            <v-list-item
            v-if="$api.auth.is_super"
            to="/orders">
                <v-list-item-icon>
                    <v-icon>receipt_long</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Prestiti</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- members -->
            <v-list-group
            v-if="$api.auth.is_super"
            prepend-icon="groups"
            no-action>
                <template #activator>
                    <v-list-item-content>
                        <v-list-item-title>Soci</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item to="/members">
                    <v-list-item-content>
                        <v-list-item-title>Tutti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item to="/members_renews">
                    <v-list-item-content>
                        <v-list-item-title>Rinnovi tessere</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <!-- products -->
            <v-list-group
            v-if="$api.auth.is_super"
            prepend-icon="widgets"
            no-action>
                <template #activator>
                    <v-list-item-content>
                        <v-list-item-title>Oggetti</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                to="/products"
                exact>
                    <v-list-item-content>
                        <v-list-item-title>Tutti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <v-list-item
            v-if="$api.auth.is_super"
            to="/headquarters">
                <v-list-item-icon>
                    <v-icon>map</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Sedi</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item to="/libraries">
                <v-list-item-icon>
                    <v-icon>domain</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Biblioteche</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- analytics -->
            <v-list-group
            v-if="$api.auth.is_super"
            prepend-icon="bar_chart"
            no-action>
                <template #activator>
                    <v-list-item-content>
                        <v-list-item-title >Analisi</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                to="/analytics"
                exact>
                    <v-list-item-content>
                        <v-list-item-title>Globale</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                to="/analytics/orders"
                exact>
                    <v-list-item-content>
                        <v-list-item-title>Report sui prestiti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                to="/analytics/customers"
                exact>
                    <v-list-item-content>
                        <v-list-item-title>Report sui soci</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
            --------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <v-list-item
            v-if="$api.auth.is_super"
            class="grey--text text--darken-1 mt-3">
                <v-list-item-content>
                    <v-list-item-title>Serivizi</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!--  -->
            <v-list-item
            v-if="$api.auth.is_super"
            to="/histories"
            exact>
                <v-list-item-icon>
                    <v-icon>calendar_month</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Storia</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!--  -->
            <v-list-item
            v-if="$api.auth.is_super"
            to="/tickets"
            exact>
                <v-list-item-icon>
                    <v-icon>confirmation_number</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Tickets</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!--  -->
            <v-list-item
            v-if="$api.auth.is_super"
            to="/gevents"
            exact>
                <v-list-item-icon>
                    <v-icon>calendar_month</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Events</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- media -->
            <v-list-item
            v-if="$api.auth.is_super"
            to="/files"
            exact>
                <v-list-item-icon>
                    <v-icon>storage</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Files</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
        --------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <template #append>
            <v-divider></v-divider>

            <v-list-item
            to="/settings"
            exact>
                <v-list-item-icon>
                    <v-icon>settings</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Impostazioni</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'sidebar'
}
</script>