import Auth from './Auth'
import File from './File'
import Gevent from './Gevent'
import History from './History'
import Library from './Library'
import Notify from './Notify'
import Order from './Order'
import Product from './Product'
import Setting from './Setting'
import User from './User'
import Headquarter from './Headquarter'
import UserRenew from './UserRenew'

export const resources = {
    settings: {
        v: 'v1',
        path: 'settings',
        class: Setting
    },
    users_renews: {
        v: 'v1',
        path: 'users_renews',
        class: UserRenew
    },
    libraries: {
        v: 'v1',
        path: 'libraries',
        class: Library
    },
    gevents: {
        v: 'v1',
        path: 'events',
        class: Gevent
    },
    notifications: {
        v: 'v1',
        path: 'notifications',
        class: Notify
    },
    auth: {
        v: 'v1',
        path: 'auths',
        class: Auth
    },
    headquarters: {
        v: 'v1',
        path: 'headquarters',
        class: Headquarter
    },
    orders: {
        v: 'v1',
        path: 'orders',
        class: Order
    },
    files: {
        v: 'v1',
        path: 'files',
        class: File
    },
    users: {
        v: 'v1',
        path: 'users',
        class: User
    },
    history: {
        v: 'v1',
        path: 'history',
        class: History
    },
    products: {
        v: 'v1',
        path: 'products',
        class: Product
    }
}

export function genResources(config, tools) {
    for (const key in resources) {
        const resource = resources[key]
        const Class = resource.class
        resources[key] = new Class(config, tools, resource.v, resource.path)
    }

    return resources
}
